<template>
  <v-col v-if="courses.length === 0">
    <v-icon x-large class="mt-12 pt-6">mdi-package-variant</v-icon>
    <p class="text--secondary">No courses added</p>
  </v-col>

  <v-col v-else class="pa-0 overflow-y-auto" justify="start" style="max-height: 500px">
    <EditTimetable
      :timetableOverview="bulkAddedCoursesOverview"
      :canEdit="true"

      :showHeader="false"
      :allowPreview="true"

      :fallCourses="courseCodeMapping"
      :winterCourses="courseCodeMapping"
      :summerCourses="courseCodeMapping"

      @updated="$emit('updated', $event)"
    />
  </v-col>
</template>

<script>
import EditTimetable from '@/components/timetable/controls/EditTimetable'

export default {
  components: { EditTimetable },
  props: {
    courses: {
      type: Array,
      default: () => []
    },
    semester: { type: String, default: 'Unknown' }
  },
  data () {
    const data = {
      bulkAddedCoursesOverview: {}
    }
    data.bulkAddedCoursesOverview[this.semester] = this.courses

    return data
  },
  computed: {
    // Converts courses array to a hash where key is the code
    courseCodeMapping () {
      const result = {}

      this.courses.forEach((course) => {
        result[course.code] = course.chosen
      })

      return result
    }
  },
  methods: {
  }
}
</script>

<style scoped>
p {
  margin: 0;
}

.courseRow {
  border-width: 2px;
  border-radius: 10px;
}
</style>
