import { createEventsForSection, sanitizeCourseData } from '@/utils/shared/courses'

export default class CalendarEventManager {
  /**
   * Add given course object to the list of events.
   *
   * @param events {any[]} The place to store newly created events. Will be mutated!!
   * @param courseId {string}
   * @param courseData {any} Should be same data returned by graphql endpoint.
   * @param opts {{ isTemporary: boolean }} Additional options to customize the event
   * */
  static addCourse (courseData, courseId, events, opts = {}) {
    const target = []

    sanitizeCourseData(courseData)
    if (courseData.lecture) createEventsForSection(courseId, courseData, 'lecture', target, opts)
    if (courseData.tutorial) createEventsForSection(courseId, courseData, 'tutorial', events, opts)

    events.push(...target)
  }

  /**
   * Remove all events with given id from target
   *
   * @param id {string}
   * @param target {any[]}
   * */
  static removeEvent (id, target) {
    // Go backwards to remove the need for updating splice index
    for (let i = target.length - 1; i >= 0; i--) {
      const curr = target[i]
      if (curr.id === id) {
        target.splice(i, 1)
      }
    }
  }
}
