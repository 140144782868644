import { shuffleArray } from '@/utils/shared/helpers'
import { getUnaryConstrainKey } from '@/utils/CSP/constraints'
import { timetableTimeToDateRange } from '@/utils/shared/timetableDateTime'
import { GenerateTimeTable } from '@/utils/timetable/generate-timetables'
import { sanitizeCourseData } from '@/utils/shared/courses'
import Bugsnag from '@bugsnag/js'

function formatCoursesChosen (coursesForSection) {
  let course
  for (const courseID in coursesForSection) {
    course = coursesForSection[courseID]
    sanitizeCourseData(course)
  }
}

/**
 * Assign timetable lectures/tutorials given semester and configuration.
 * @param {{
 *   courses: any,
 *   lockedSections: any,
 *   target: any,
 *   generatorOpts: {[x: string]: any}
 * }} opts
 * */
export default async function Generate (opts) {
  const { courses, lockedSections, target, generatorOpts } = opts
  const result = {
    error: null
  }

  // Must have at least ONE course chosen!
  if (Object.keys(courses).length === 0) return

  for (const courseId in courses) {
    const course = courses[courseId]
    const timetable = course.data.timetableResult.timetable

    let lectures = timetable.lec.length ? timetable.lec : timetable.tut
    let tutorials = timetable.lec.length ? timetable.tut : []

    // If any of the lectures/tutorials are locked, remove all the others
    if (lectures.find(lec => lockedSections[getUnaryConstrainKey(courseId, lec.section)])) {
      lectures = lectures.filter(lec => lockedSections[getUnaryConstrainKey(courseId, lec.section)])
    }
    if (tutorials.find(tut => lockedSections[getUnaryConstrainKey(courseId, tut.section)])) {
      tutorials = tutorials.filter(tut => lockedSections[getUnaryConstrainKey(courseId, tut.section)])
    }

    // TODO: Optimization -- remove lectures/tutorials that overlap with any blocked-off times.

    // Filter for only available sections
    lectures = lectures.filter(lec => lec.isAvailable || lec.section.indexOf('Blocked') > -1)
    tutorials = tutorials.filter(tut => tut.isAvailable)

    // Need start and end time for each lecture/tutorial
    // so CSP can check the relevant constraints
    for (const lecture of lectures) {
      lecture.times.forEach(lec => {
        const { start, end } = timetableTimeToDateRange(lec)
        lec.start = start
        lec.end = end
      })
    }

    for (const tutorial of tutorials) {
      tutorial.times.forEach(tut => {
        const { start, end } = timetableTimeToDateRange(tut)
        tut.start = start
        tut.end = end
      })
    }

    // Shuffle lectures and tutorials so we get
    // a unique random output each time (hopefully...LOL)
    course.lectures = shuffleArray(lectures)
    course.tutorials = shuffleArray(tutorials)

    // Nuke whatever we don't need...easier on my eyes for debugging :)
    delete course.data
    delete course.lecture
    delete course.tutorial
  }

  let newTimetable

  try {
    newTimetable = GenerateTimeTable(courses, generatorOpts)
  } catch (error) {
    result.error = 'generator_error'

    console.error('Error generating timetable:')
    console.error(error)
    Bugsnag.notify(error)
  }

  // Timetable present --> we're gucci
  if (newTimetable) {
    // Update the timings chosen
    for (const courseID in target) {
      target[courseID].lecture = newTimetable[courseID].lecture
      target[courseID].tutorial = newTimetable[courseID].tutorial
    }

    formatCoursesChosen(target)
  } else {
    // Timetable generation failed --> constraints make it impossible!!
    console.error('Timetable generation failed for courses:')
    console.error(JSON.stringify(courses))

    if (generatorOpts.avoidConflicts) {
      result.error ||= 'constraints'
    } else {
      result.error ||= 'unknown'
    }
  }

  return result
}
