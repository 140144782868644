<template>
  <v-dialog v-model="model" width="max-content" persistent>
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        {{ body }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text @click="$emit('cancel')"> Cancel </v-btn>
        <v-btn color="accent" @click="$emit('ok')"> OK </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ExitModal',
  props: {
    model: {},
    title: { type: String, default: 'Exit' },
    body: { type: String, default: 'Are you sure?' }
  }
}
</script>
