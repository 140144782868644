import { getKeyForCourseAndSection, getUnaryConstrainKey } from './constraints'
import { isEmptyObject } from '@/utils/shared/helpers'

const deepCloneState = (state) => {
  const newState = { }
  for (const cID in state) {
    const curr = state[cID]

    newState[cID] = {
      color: curr.color,
      lectures: [...(curr.lectures || [])],
      tutorials: [...(curr.tutorials || [])]
    }
  }

  return newState
}

const deepCloneAssignment = (assignment) => {
  return { ...assignment }
}

/**
 * Find and return variables with smallest domain
 * @param {Array<string>} vars
 * @returns {String}
 * */
const pickVariable = (state, vars) => {
  let smallest = ''
  let smallestSize = Number.POSITIVE_INFINITY

  for (const variable of vars) {
    const lecLength = state[variable].lectures.length
    const tutLength = (state[variable].tutorials || []).length
    const size = lecLength + tutLength

    if (size < smallestSize) {
      smallest = variable
      smallestSize = size
    }
  }

  return smallest
}

/**
 * Represents a node within a larger tree.
 */
class Node {
  /**
   * Original list of variables. DO NOT MODIFY!!
   * This is used to check if we are at goal state.
   */
  variables = []

  /**
   * The set of variables we can choose next.
   * Does not matter which we pick.
   */
  currVariables = []

  assignedVariable = ''

  /**
   * The parent of this node. Null if root.
   * @type {Node}
   */
  parent = null

  /**
   * The set of constraints to check against.
   */
  constraints = { }
  unaryConstraints = { }

  /** Cached */
  hasConstraints = false
  hasUnaryConstraints = false

  /** Given state. */
  state = { }

  assignments = { }

  constructor (parent, constraints, unaryConstraints, { hasConstraints, hasUnaryConstraints }) {
    this.parent = parent
    this.constraints = constraints
    this.unaryConstraints = unaryConstraints

    this.hasConstraints = hasConstraints
    this.hasUnaryConstraints = hasUnaryConstraints
  }

  assignVar (varType, variable, assignment) {
    if (!this.assignments[variable]) this.assignments[variable] = { }
    this.assignments[variable][varType] = assignment

    this.assignedVariable = variable
  }

  propagate () {
    let assignments
    const goalState = this.isGoalState()

    if (goalState) return this.assignments
    else {
      // Pick a new variable to assign based on
      // MRV heuristic and remove it from available set
      const variable = pickVariable(this.state, this.currVariables)
      this.currVariables.splice(this.currVariables.indexOf(variable), 1)

      const assignmentsCopy = deepCloneAssignment(this.assignments)

      //
      // Foward Checking: Go through all other courses in stateCpy
      // and see which lectures/tutorials can be pruned based on
      // what we assigned for "this.assignedVariable".
      //
      // each constraint has exactly two courses, so do this
      // on every propagation because "this.assignedVariable"
      // is already done, leaving one remaining.
      //
      const stateCpy = deepCloneState(this.state)
      if (this.hasConstraints) {
        const thisCourse = this.assignments[this.assignedVariable]

        // Other variables to check
        const variablesToCheck = this.variables.filter(v => v !== this.assignedVariable)
        for (const cId of variablesToCheck) {
          const course = stateCpy[cId]

          const thisLecSection = thisCourse.lecture.section
          const thisTutSection = (thisCourse.tutorial || {}).section

          // Prune overlapping lectures
          course.lectures = course.lectures.filter(lec => {
            let hasOverlappingLec = false
            let hasOverlappingTut = false

            const lecKey = getKeyForCourseAndSection(this.assignedVariable, cId, thisLecSection, lec.section)
            hasOverlappingLec = this.constraints[lecKey]

            if (thisTutSection) {
              const tutKey = getKeyForCourseAndSection(this.assignedVariable, cId, thisTutSection, lec.section)
              hasOverlappingTut = this.constraints[tutKey]
            }

            return !hasOverlappingLec && !hasOverlappingTut
          })

          // Prune overlapping tutorials
          // This loop is identical to the one above, but we access
          // tutorials instead of lectures
          // TODO: De-dupe this!!
          //
          // ONLY PRUNE IF TUTORIALS EXISTED! There is the scenario where it never did to begin with,
          // so we need to make sure that we don't falsely cause a DWO.
          if (course.tutorials && course.tutorials.length > 0) {
            course.tutorials = course.tutorials.filter(tut => {
              let hasOverlappingLec = false
              let hasOverlappingTut = false

              const lecKey = getKeyForCourseAndSection(this.assignedVariable, cId, thisLecSection, tut.section)
              hasOverlappingLec = this.constraints[lecKey]

              if (thisTutSection) {
                const tutKey = getKeyForCourseAndSection(this.assignedVariable, cId, thisTutSection, tut.section)
                hasOverlappingTut = this.constraints[tutKey]
              }

              return !hasOverlappingLec && !hasOverlappingTut
            })

            // DWO! We've exhausted everything with forward check. Only if it's not a blocked time!!
            // TODO: We should be able to remove the check for '- Blocked' once we handle
            //       blocked times correctly!
            if (course.tutorials.length === 0 && !course.lectures[0].section.includes('- Blocked')) {
              return null
            }
          }
        }
      }

      const courseToAssign = stateCpy[variable]
      const { lectures, tutorials } = courseToAssign

      // Ready to start assigning next variable
      for (const lec of lectures) {
        if (tutorials.length > 0) {
          for (const tut of tutorials) {
            const n = new Node(this, this.constraints, this.unaryConstraints, {
              hasConstraints: this.hasConstraints,
              hasUnaryConstraints: this.hasUnaryConstraints
            })
            n.variables = [...(this.variables)]
            n.currVariables = [...(this.currVariables)]
            n.state = stateCpy
            n.assignments = assignmentsCopy

            n.assignVar('lecture', variable, lec)
            n.assignVar('tutorial', variable, tut)

            if (n.checkConstraints()) {
              // Propagate. If failed, keep going
              assignments = n.propagate()
              if (assignments) return assignments
            }
          }
        } else { // Only work with lectures
          const n = new Node(this, this.constraints, this.unaryConstraints, {
            hasConstraints: this.hasConstraints,
            hasUnaryConstraints: this.hasUnaryConstraints
          })
          n.variables = [...(this.variables)]
          n.currVariables = [...(this.currVariables)]
          n.state = stateCpy
          n.assignments = assignmentsCopy

          n.assignVar('lecture', variable, lec)

          if (n.checkConstraints()) {
            // Propagate. If failed, keep going
            assignments = n.propagate()
            if (assignments) return assignments
          }
        }
      }

      // DWO! Failed to satisfy.
      return null
    }
  }

  /** True iff all constraints satisfied, false otherwise. */
  checkConstraints () {
    if (this.hasUnaryConstraints) {
      // Ensure lecture and tutorial are allowed in the first place
      for (const cId in this.assignments) {
        const course = this.assignments[cId]

        if (this.unaryConstraints[getUnaryConstrainKey(cId, course.lecture.section)]) return false
        if (course.tutorial && this.unaryConstraints[getUnaryConstrainKey(cId, course.tutorial.section)]) return false
      }
    }

    if (!this.hasConstraints) return true

    const cId1 = this.assignedVariable
    for (const cId2 in this.assignments) {
      const course1 = this.assignments[cId1]
      const course2 = this.assignments[cId2]

      if (cId1 === cId2) {
        // Lecture can overlap with its own tutorial/practical
        if (course1.tutorial) {
          const lecTutKey = getKeyForCourseAndSection(cId1, cId1, course1.lecture.section, course1.tutorial.section)
          if (this.constraints[lecTutKey]) return false
        }

        continue
      }

      // Can lectures be together
      const lecKey = getKeyForCourseAndSection(cId1, cId2, course1.lecture.section, course2.lecture.section)
      if (this.constraints[lecKey]) return false

      // Can tutorial and lecture be together
      if (course1.tutorial) {
        const lecTutKey = getKeyForCourseAndSection(cId1, cId2, course1.tutorial.section, course2.lecture.section)
        if (this.constraints[lecTutKey]) return false
      }

      if (course2.tutorial) {
        const lecTutKey = getKeyForCourseAndSection(cId1, cId2, course1.lecture.section, course2.tutorial.section)
        if (this.constraints[lecTutKey]) return false
      }

      // Can tutorials be together
      if (course1.tutorial && course2.tutorial) {
        const tutKey = getKeyForCourseAndSection(cId1, cId2, course1.tutorial.section, course2.tutorial.section)
        if (this.constraints[tutKey]) return false
      }
    }

    return true
  }

  /** True iff reached goal state. */
  isGoalState () {
    for (const d of this.variables) {
      if (!this.assignments[d]) return false
    }
    return true
  }
}

/**
 * A CSP tree. This will instantiate the
 * first variable, then carry computation forward.
 */
export default class CSPTree {
  initialState = { }

  root;
  variables;
  currVariables;

  /**
   * The set of constraints to check against.
   */
  constraints = {}

  /**
   * Equality constraints, i.e. each key that evaluates to true is not allowed
   *
   * @type {Object<string, boolean>}
   */
  unaryConstraints = {}

  constructor (initState, variables) {
    this.variables = variables
    this.currVariables = [...variables]

    this.initialState = initState
  }

  log (...a) { console.log('[CSPTree]', ...a) }

  /** Assign courses in the variables to a different child and propagate. */
  createAssignment () {
    if (this.variables.length === 0) return null
    let assignment

    const hasConstraints = !isEmptyObject(this.constraints)
    const hasUnaryConstraints = !isEmptyObject(this.unaryConstraints)

    const variable = pickVariable(this.initialState, this.currVariables)
    this.currVariables.splice(this.currVariables.indexOf(variable), 1)

    const { lectures, tutorials } = this.initialState[variable]

    // Assign every possible combination of lecture + tutorial to a different child
    for (const lec of lectures) {
      if (tutorials.length > 0) { // Sometimes only a lecture is defined!
        for (const tut of tutorials) {
          const stateCpy = deepCloneState(this.initialState)

          const n = new Node(this, this.constraints, this.unaryConstraints, {
            hasConstraints,
            hasUnaryConstraints
          })
          n.variables = [...(this.variables)]
          n.currVariables = [...(this.currVariables)]
          n.state = stateCpy

          n.assignVar('lecture', variable, lec)
          n.assignVar('tutorial', variable, tut)

          if (n.checkConstraints()) {
            // Propagate. If failed, try with different combination
            assignment = n.propagate()
            if (assignment) {
              return assignment
            }
          }
        }
      } else {
        const stateCpy = deepCloneState(this.initialState)

        const n = new Node(this, this.constraints, this.unaryConstraints, {
          hasConstraints,
          hasUnaryConstraints
        })
        n.variables = [...(this.variables)]
        n.currVariables = [...(this.currVariables)]
        n.state = stateCpy

        n.assignVar('lecture', variable, lec)

        if (n.checkConstraints()) {
          // Propagate. If failed, keep going
          assignment = n.propagate()
          if (assignment) {
            return assignment
          }
        }
      }
    }

    return assignment
  }
}
