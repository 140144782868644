<template>
  <v-row
      class="my-2 pl-4 pr-3"
      :class="classes"
      align="center"
      justify="center"
      data-cy="semester_toggle_smallscreen"
  >
    <v-btn
        v-for="{ semester, icon, label } in semesterToggles"
        :key="semester"

        small class="mr-1 grow text-capitalize rounded-lg"
        style="height: 30px;"
        :class="{ hidden: !semestersEnabled(semester) }"
        @click="$emit('toggle', semester)" :color="isChosen(semester) ? 'accent': ''" tile elevation="0" :data-cy="semester + '_toggle'"
    >
      <v-icon class="mr-1" small> {{icon}} </v-icon>{{label}}
    </v-btn>
  </v-row>
</template>
<script>
export default {
  props: {
    semesterToggles: {
      type: Array
    },
    semestersEnabled: {
      type: Function
    },
    isChosen: {
      type: Function
    },
    classes: {
      type: String,
      default: 'grow-0'
    }
  }
}
</script>
