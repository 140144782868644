import { getKeyForCourseAndSection, getUnaryConstrainKey, sectionsOverlap } from '@/utils/CSP/constraints'
import { isSectionOnline } from '@/utils/shared/courses'

/**
 * Helper to build different constraints types across
 * both lectures and tutorials, and their combinations.
 */
export const buildForSection = (constraints, cId1, cId2, sec1, sec2) => {
  // Build lecture constraints
  for (const section1 of sec1) {
    for (const section2 of sec2) {
      // Add constraints!
      // We create two keys because either can come first.
      // This is done for easier referencing later...
      const key1 = getKeyForCourseAndSection(cId1, cId2, section1.section, section2.section)
      const key2 = getKeyForCourseAndSection(cId2, cId1, section2.section, section1.section)

      if (sectionsOverlap(section1, section2)) {
        constraints[key1] = true
        constraints[key2] = true
      }
    }
  }
}

/** Given course data, return map of constraints */
export function buildConflictConstraints (courses) {
  const constraints = { }

  for (const cId1 in courses) {
    for (const cId2 in courses) {
      const course1 = courses[cId1]
      const course2 = courses[cId2]

      if (cId1 === cId2) {
        // Constraints between it's OWN lectures and tutorials
        if (course1.tutorials) buildForSection(constraints, cId1, cId2, course1.lectures, course1.tutorials)
        continue
      }

      // Constraints between lectures
      buildForSection(constraints, cId1, cId2, course1.lectures, course2.lectures)

      // Constraints between tutorials and lectures
      if (course1.tutorials) {
        buildForSection(constraints, cId1, cId2, course1.tutorials, course2.lectures)
      }
      if (course2.tutorials) {
        buildForSection(constraints, cId1, cId2, course1.lectures, course2.tutorials)
      }

      // Constraints between tutorials
      if (course1.tutorials && course2.tutorials) buildForSection(constraints, cId1, cId2, course1.tutorials, course2.tutorials)
    }
  }

  return constraints
}

export function buildUnaryOnlineOnlyConstraints (courses) {
  const constraints = { }

  for (const cId in courses) {
    const course = courses[cId]

    course.lectures.forEach(lec => {
      if (!isSectionOnline(lec)) constraints[getUnaryConstrainKey(cId, lec.section)] = true
    });

    (course.tutorials || []).forEach(tut => {
      if (!isSectionOnline(tut)) constraints[getUnaryConstrainKey(cId, tut.section)] = true
    })
  }

  return constraints
}
