<template>
  <v-container
    @click="handleClick"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"

    class="calendar-event-container font-weight-bold"
    :class="{ 'blockedEvent': isBlockedTime, isTemporaryTime }"
    style="height: 100%; position: relative;"

    :data-cy="calendarEvent.event.section"
  >
    <v-col class="px-1 py-1" :class="{ 'tiny-container': isAsync }" style="height: 100%">
      <v-row>
        <p v-if="!isBlockedTime" class="overflow-ellipsis" :style="textStyle">
          <span>{{ courseCode }} • {{ section }}</span>
        </p>
        <p v-else class="font-weight-medium"> BLOCKED </p>

        <v-spacer v-if="!isAsync"/>
        <p v-if="isBlockedTime && renderHovered()" class="font-italic font-weight-regular"> Click to unblock </p>

        <!-- Small controls for short events (1h) -->
        <v-menu v-if="isSmallEvent && editable">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                x-small
                icon
                v-bind="attrs"
                v-on="on"

                :class="{ 'hidden-soft': !renderHovered() }"
                class="more-actions-btn"

                color="white"
              >
                <v-icon :style="textStyle">mdi-dots-vertical</v-icon>
              </v-btn>
          </template>

          <v-list dense>
            <v-list-item @click="$emit('lockToggle', calendarEvent.event)">
              <v-list-item-icon class="mx-2">
                <v-icon small> {{ isLocked() ? 'mdi-lock' : 'mdi-lock-open-outline' }} </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ isLocked() ? 'Unlock': 'Lock' }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item class="small_edit" data-cy="edit_course" @click="edit">
              <v-list-item-icon class="mx-2">
                <v-icon small> mdi-pencil </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Edit
              </v-list-item-title>
            </v-list-item>

            <v-list-item data-cy="delete_course" @click="del">
              <v-list-item-icon class="mx-2">
                <v-icon small color="redText"> mdi-delete </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Delete
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- Tiny controls for async courses... -->
        <v-btn class="ml-4 tiny_edit" v-if="isAsync && renderHovered()" data-cy="edit_course" @click="edit" icon x-small color="white">
          <v-icon small :style="textStyle">mdi-pencil</v-icon>
        </v-btn>

        <v-btn class="" v-if="isAsync && renderHovered()" data-cy="delete_course" @click="del" icon x-small color="white">
          <v-icon small :style="textStyle">mdi-delete</v-icon>
        </v-btn>
      </v-row>

      <v-row align="center" class="font-weight-regular" >
        <p v-if="!isOnline && location&& location.room" class="mr-1" :style="textStyle"> {{ location.room }} </p>
        <LockEventButton
          v-if="isSmallEvent && editable"
          :locked="isLocked()"
          :hidden="!(isLocked() || (renderHovered() && !shortEvent))"
          @toggle="$emit('lockToggle', calendarEvent.event)"

          :styling="textStyle"
          data-html2canvas-ignore
        />
      </v-row>

      <v-row v-if="!shortEvent && !isBlockedTime" class="font-weight-regular">
        <p :style="textStyle"> {{ startTime }} - {{ endTime }} </p>
      </v-row>

      <!-- Actions for larger events -->
      <v-row class="w-full large-actions" align="center" v-if="!isBlockedTime && !shortEvent && !isAsync">
        <LockEventButton
          class="ml-1"
          :locked="isLocked()"
          :hidden="!(isLocked() || (renderHovered() && !shortEvent))"
          @toggle="$emit('lockToggle', calendarEvent.event)"
          :styling="textStyle"
          data-html2canvas-ignore
        />

        <v-spacer />

        <v-btn
            class="large_edit" :class="{ 'hidden-soft': !renderHovered() }"
            data-cy="edit_course" @click="edit"
            icon small color="white" style="margin-right: -5px;"
        >
          <v-icon small :style="textStyle">mdi-pencil</v-icon>
        </v-btn>

        <v-btn data-cy="delete_course" @click="del" icon small color="white" :class="{ 'hidden-soft': !renderHovered() }">
          <v-icon small :style="textStyle">mdi-delete</v-icon>
        </v-btn>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { getUnaryConstrainKey } from '@/utils/CSP/constraints'
import { isSectionAsync, isSectionOnline } from '@/utils/shared/courses'
import LockEventButton from '@/components/timetable/controls/LockEventButton'
import { localTimeString } from '@/utils/shared/helpers'
import { useAllStores } from '@/stores/useAllStores'

export default {
  components: { LockEventButton },
  props: {
    calendarEvent: {},
    lockedSections: {},
    editable: { type: Boolean, default: true }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data () {
    const { event, singline } = this.calendarEvent

    const eventNameSegments = event.name.split('•').map(segment => segment.trim())
    // const eventTimeSegments = timeSummary().split('-').map(segment => segment.trim())

    const courseSection = eventNameSegments[1].split('-')[1]

    return {
      hovered: false,
      isOnline: event.delivery && isSectionOnline(event.delivery),
      isAsync: isSectionAsync(event),

      courseCode: eventNameSegments[0],
      section: (courseSection || '').trim(),

      endTime: localTimeString(event.end), // eventTimeSegments[1],
      startTime: localTimeString(event.start), // eventTimeSegments[0],
      location: event.location,

      shortEvent: singline,
      isBlockedTime: event.isBlockedTime,
      isTemporaryTime: event.isTemporaryTime
    }
  },
  computed: {
    isSmallEvent () { return !this.isBlockedTime && !this.isAsync && this.shortEvent },
    textStyle () {
      const color = this.calendarEvent.event.color
      return `color: var(--v-${color}Text-base)`
    }
  },
  methods: {
    handleClick (clickEvent) {
      // If not blocked section, let it propagate
      if (!this.isBlockedTime || !this.editable) return

      clickEvent.preventDefault()
      clickEvent.stopPropagation()

      this.$emit('unblock', this.calendarEvent.event)
    },
    isLocked () {
      const { event: { id, section } } = this.calendarEvent
      const uniqueId = getUnaryConstrainKey(id, section)

      return !!this.lockedSections[uniqueId]
    },
    edit (clickEvent) {
      clickEvent.preventDefault()
      clickEvent.stopPropagation()
      this.$emit('edit', this.calendarEvent)
    },
    del (clickEvent) {
      clickEvent.preventDefault()
      clickEvent.stopPropagation()
      this.$emit('delete', this.calendarEvent)
    },
    color (clickEvent) {
      clickEvent.preventDefault()
      clickEvent.stopPropagation()
      this.$emit('color', this.calendarEvent)
    },
    renderHovered () {
      return (this.hovered || this.store.data.onMobile) && this.editable
    }
  }
}
</script>

<style scoped>
.blockedEvent {
  background: #D9D9D9;
  height: 100%;
}
.blockedEvent p {
  color: rgba(0, 0, 0, 0.5);
}

.more-actions-btn {
  position: absolute;
  bottom: 5px;
  right: -3px;

  color: black;
  z-index: 50;
}

.calendar-event-container {
  position: relative;
}

p {
  margin: 0;
  color: #FFFFFF;
}

.v-btn {
  margin: 0 5px;
}

.large-actions {
  position: absolute;
  bottom: 15px;

  right: 0;
  margin-right: 0px;
}

.tiny-container {
  position: absolute;
  top: 9px;
}

</style>
