<!--List of lecture/tutorial/practical timings-->
<template>
  <v-list-item-group>
    <v-list-item
      v-for="sec in sections"
      :key="sec.section"

      :selectable="canEdit"
      :ripple="false"

      @mouseenter="handleMouseOver(type, sec)"
      @mouseleave="handleMouseLeave()"

      class="pointer"
      :class="{ 'disabled-item': isChosen(type, sec) }"

      :data-cy="`title_${sectionName(sec)}`"
      @click="handleClick({ type, section: sec })"
    >
      <v-list-item-content>
        <v-list-item-title class="text-body-2">{{ sectionName(sec) }}</v-list-item-title>
        <v-list-item-subtitle v-for="(time, idx) in sec.times" :key="idx + time.day + time.time">
          {{ timeEntry(time) }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="sec.times.length === 0">
          Asynchronous
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="canEdit">
        <v-row>
          <v-checkbox
            v-if="allowPreview && hovered && !isChosen(type, sec)"
            :class="{ 'invisible': showPreview !== sec.section }"
            :input-value="isChosen(type, sec)"
            @click="$emit('handleUpdateCourse', { type, section: sec })"

            :data-cy="`select_${sectionName(sec)}`"
          />

          <v-checkbox
            v-if="isChosen(type, sec)"
            :input-value="isChosen(type, sec)"
            @click="$emit('handleUpdateCourse', { type, section: sec })"

            :data-cy="`select_${sectionName(sec)}`"
          />
        </v-row>
      </v-list-item-action>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
export default {
  props: ['type', 'sections', 'courseChosen', 'canEdit', 'allowPreview'],
  data () {
    return {
      showPreview: null,
      hovered: false
    }
  },
  methods: {
    sectionName (section) {
      return section.section.split('-')[1].trim()
    },
    isChosen (type, section) {
      const sectionName = section.section
      const chosenName = this.courseChosen[type].section

      return sectionName === chosenName || sectionName.endsWith(chosenName)
    },
    timeEntry ({ day, time }) {
      let dayStr = day.toLowerCase().slice(1)
      dayStr = day[0] + dayStr

      return `${dayStr} ${time}`
    },
    handleMouseOver (type, section) {
      if (!this.canEdit) return

      this.hovered = true
      this.showPreview = section.section

      this.$gtag.event('tt_edit_panel_preview_course_section', { value: 1 })
      this.$emit('handlePreview', { type, section: section })
    },
    handleMouseLeave () {
      this.hovered = false
      this.showPreview = null
    },
    handleClick ({ type, section }) {
      if (!this.canEdit) return

      this.$gtag.event('tt_edit_panel_select_section', { value: 1 })

      this.$emit('handleUpdateCourse', { type, section })
    }
  }
}
</script>

<style scoped>
.disabled-item {
  opacity: 0.5;
  pointer-events: none;
}
</style>
