import CSPTree from '../CSP/CSPTree'
import { isSectionAsync } from '../shared/courses'
import {
  buildConflictConstraints,
  buildUnaryOnlineOnlyConstraints
} from '@/utils/timetable/constraint_builders'

/** Map list of courses to their cache */
let CONFLICT_CONSTRAINTS_CACHE = {}

/**
 * Sort all the lecture/tutorial timings in the courses given
 * based on time of day preference and section start time.
 * This will sort in-place.
 *
 * @param {any} courses
 * @param {number} pref The time of day, 0 --> Early, 2 --> Late, etc.
 */
function sortSectionsByTimeOfDayPreference (courses, pref) {
  const morning = new Date(null); morning.setHours(8)
  const afternoon = new Date(null); afternoon.setHours(13)
  const evening = new Date(null); evening.setHours(17)

  /**
   * A function that sorts sec1 and sec2 based on
   * the preference chosen. We want the ones that are
   * closer in distance to chosen time of day to be sorted
   * ahead.
   */
  const timeOfDaySortMapping = {
    0: (sec1, sec2) => isSectionAsync(sec1) ? 0 : (Math.abs(morning - sec1.times[0].start) - Math.abs(morning - sec2.times[0].start)),
    2: (sec1, sec2) => isSectionAsync(sec1) ? 0 : (Math.abs(evening - sec1.times[0].start) - Math.abs(evening - sec2.times[0].start))
  }

  for (const cId in courses) {
    const course = courses[cId]

    // Sort timings within sections so we can easily pick which is the earliest
    // Special case is when we want evening course, so try to choose latest possible.
    // TODO: This can be improved by finding the distance between AVERAGE start time of ALL sections.
    //
    course.lectures.forEach(lec => lec.times.sort((time1, time2) => pref === 2 ? time2.start - time1.start : time1.start - time2.start));
    (course.tutorials || []).forEach(tut => tut.times.sort((time1, time2) => pref === 2 ? time2.start - time1.start : time1.start - time2.start))

    course.lectures.sort(timeOfDaySortMapping[pref]);
    (course.tutorials || []).sort(timeOfDaySortMapping[pref])
  }
}
/** Clear conflict constraints cache. Useful when adding/removing courses. */
export function InvalidateCSPCache () { CONFLICT_CONSTRAINTS_CACHE = {} }

/**
 * Generate a timetable given a hash of courses.
 * This can potentially be an expensive process when number of
 * courses gets extremely large.
 *
 * Each course given MUST have at least one lecture defined.
 *
 * @param {{ [x: string]: { lecs: any[], tuts: any[] } }} courses
 * @param {{ avoidConflicts: boolean, onlineOnly: boolean, timeOfDayPreference: 0 | 1 | 2 }} options
 */
export function GenerateTimeTable (courses, options = {}) {
  const domain = []
  let key = ''

  for (const cID in courses) {
    key += cID
    domain.push(cID)
  }

  const csp = new CSPTree(courses, domain)

  // Assign constraints
  if (options.avoidConflicts) {
    let cached = CONFLICT_CONSTRAINTS_CACHE[key]
    if (!cached) {
      console.time('conflict constraints')
      cached = buildConflictConstraints(courses)
      console.timeEnd('conflict constraints')
      CONFLICT_CONSTRAINTS_CACHE[key] = cached
    } else {
      console.log('[Cached] Conflict constraints')
    }
    csp.constraints = cached
  }

  if (options.onlineOnly) {
    csp.unaryConstraints = buildUnaryOnlineOnlyConstraints(courses)
  }

  // Only sort if user does not want balanced
  if (options.timeOfDayPreference !== 1) {
    sortSectionsByTimeOfDayPreference(courses, options.timeOfDayPreference)
  }

  console.time('csp')
  // Generate timetable
  const result = csp.createAssignment()
  console.timeEnd('csp')

  return result
}
