<template>
  <div class=" grow" @mouseenter="hovered = true" @mouseleave="hovered = false">
    <div class="d-flex flex-row items-center mt-2 mb-1 pl-3 pr-0 calendarHeader" :class="`${variant}Header`" :data-cy="`${variant}_toolbar`">
      <v-icon :color="semIconMap[variant].color" class="mr-2">{{ semIconMap[variant].icon }}</v-icon>
      <p class="mb-0 text-h6 mr-2"> {{year}}</p>
      <p v-if="scheduleMeta.id && !canEditTimetable && scheduleExists" class="text--secondary mb-0">@{{ scheduleMeta.createdBy.username }}</p>

      <!-- If the timetable is outdated -->
      <v-tooltip top color="warning">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-if="isTimetableOutdated" v-bind="attrs" v-on="on" class="ml-1" size="20" color="warning">mdi-alert</v-icon>
        </template>
        <span v-if="isTimetableOutdated">This timetable is outdated.</span>
      </v-tooltip>

      <v-btn
        :class="{ 'hidden-soft': softHidden }"
        class="ml-2 text-capitalize"
        text

        :ripple="false"
        :loading="downloading"
        @click="downloadTimetable()"

        data-html2canvas-ignore
      >
        <v-icon color="accent">mdi-download</v-icon>
        <span class="ml-2">Download</span>
      </v-btn>

      <v-spacer/>

      <v-btn v-if="enableUIComponents && !onMobile" @click="$emit('generate')" color="accent"
             :icon="dense || onMobile" class="px-2 mr-2 text-capitalize" :class="{ 'hidden-soft': softHidden }" text :ripple="false"
             :data-cy="variant + '_shuffle'" data-html2canvas-ignore
      >
        <v-icon>mdi-shuffle-variant</v-icon>
        <span v-if="!onMobile && !dense" class="ml-2 text--text">Shuffle</span>
      </v-btn>

      <v-menu v-if="canEditTimetable && !onMobile" v-model="filterOptsMenuOpen" transition="slide-y-transition" offset-y nudge-left="105"
              nudge-bottom="5" :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on"
                 depressed icon text
                 class="text--secondary" :class="{ 'hidden-soft': softHidden }"
                 :ripple="false" :data-cy="`${variant}_toolbar_options_menu`"
                 data-html2canvas-ignore
          >
            <v-icon color="accent">mdi-tune</v-icon>
          </v-btn>
        </template>
        <FilterOptions @close="$emit('closeFilterOpts')" @clearSemester="$emit('clearSemester')" :opts="generatorOptions" :scheduleMeta="scheduleMeta"
                       :loading="loading"/>
      </v-menu>
    </div>

    <v-sheet class="mx-1" :class="heightOffset > 0 ? 'mb-0' : 'mb-6'">
      <WeeklyCalendar
          v-if="!onMobile"
          :class="`calendarSheet mt-2 ${variant}Calendar`"
          :events="eventsToRender"

          :data-cy="`${variant}Calendar`"
      >
        <template v-slot:event="event" :data-cy="'yolo'">
          <CalendarEvent
              :key="keyForCalendarEvent(event.event)"
              :calendarEvent="event"
              :lockedSections="lockedSections"
              :editable="enableUIComponents"

              :data-cy="cyDataForEvent(event)"

              @edit="$emit('edit', $event)"
              @delete="$emit('delete', $event)"
              @unblock="$emit('unblock', $event)"

              @lockToggle="$emit('lockToggle', $event)"
          />
        </template>

        <template v-if="enableUIComponents" v-slot:interval="event">
          <BlockTime :optionsMenu="blockEventsOptionsMenu" @blockTime="$emit('handleBlockTime', $event)" :event="event" />
        </template>
      </WeeklyCalendar>

      <MobileCalendar
        v-if="onMobile"
        :events="eventsToRender"
        :lockedSections="lockedSections"
        :editable="canEditTimetable"

        @edit="$emit('edit', $event)"
        @delete="$emit('delete', $event)"
        @lockToggle="$emit('lockToggle', $event)"
      />
    </v-sheet>
  </div>
</template>

<script>
import { useAllStores } from '@/stores/useAllStores'
import FilterOptions from '@/components/timetable/FilterOptions'
import CalendarEvent from '@/components/timetable/CalendarEvent'
import BlockTime from '@/components/timetable/BlockTime'
import MobileCalendar from '@/components/timetable/mobile-calendar/MobileCalendar'
import { getUnaryConstrainKey } from '@/utils/CSP/constraints'
import { WEEK_START } from '@/utils/timetable/constants'
import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'
import Bugsnag from '@bugsnag/js'
import WeeklyCalendar from '@/components/shared/calendar/WeeklyCalendar.vue'

export default {
  props: [
    'events',
    'temporaryCourse',
    'lockedSections',
    'canEditTimetable',
    'scheduleMeta',
    'scheduleExists',
    'enableUIComponents',
    'filterOptsMenuOpen',
    'generatorOptions',
    'loading',
    'variant',
    'year',
    'dense',
    'height',
    'heightOffset'
  ],
  components: {
    WeeklyCalendar,
    CalendarEvent,
    MobileCalendar,
    FilterOptions,
    BlockTime
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data () {
    return {
      downloading: false,
      hovered: false,
      model: WEEK_START.MON,
      eventsToRender: [...(this.events)], // Must be a copy!
      blockEventsOptionsMenu: {
        owner: '',
        open: false,
        x: 0,
        y: 0
      },
      semIconMap: {
        summer: {
          icon: 'mdi-flower-tulip',
          color: 'error'
        },
        fall: {
          icon: 'mdi-leaf',
          color: 'warning'
        },
        winter: {
          icon: 'mdi-snowflake-variant',
          color: 'primary'
        }
      }
    }
  },
  watch: {
    events: function (newVal) { this.eventsToRender = [...newVal] }
  },
  computed: {
    onMobile () { return this.store.app.onMobile },
    intervalHeight () {
      if (this.heightOffset > -1) {
        const height = window.innerHeight
        return Math.max((height - this.heightOffset) / (height < 1080 ? 18 : 17), 41)
      } else {
        return 48
      }
    },
    softHidden () {
      return !this.hovered && !this.filterOptsMenuOpen
    },
    isTimetableOutdated () {
      for (const event of this.events) {
        if (event.outdated) return true
      }

      return false
    }
  },
  methods: {
    keyForCalendarEvent ({ id, name, section, start, end }) {
      return name + this.lockedSections[getUnaryConstrainKey(id, section)] + start + end
    },
    cyDataForEvent (eventData) {
      const { day, event } = eventData

      // console.log('cy data for event', deepClone(eventData))

      // Event start might be a string!
      const eventStart = typeof event.start === 'string' ? event.start : event.start.getHours()
      const semesterNameOnly = this.year.split(' ')[0]
      return `${semesterNameOnly}_calendar_event_${day.day}_${eventStart}`
    },
    async downloadTimetable () {
      this.downloading = true

      const options = {
        logging: false,
        scale: 2,
        backgroundColor: this.store.app.darkMode ? '#0F0F0F' : '#FFFFFF',
        ignoreElements: (element) => {
          // Remove blocked times from canvas, a bit hacky...
          return (
            element.className &&
            typeof (element.className) === 'string' &&
            element.className.includes('v-event-timed grey')
          )
        }
      }

      try {
        const selector = `${this.variant}Calendar`
        const timetable = document.getElementById(selector)
        const canvas = await html2canvas(timetable, options)

        canvas.toBlob((blob) => {
          const yearName = this.year.replace(' ', '-')
          saveAs(blob, `UofTIndex-Timetable-${yearName}.jpeg`, 'image/jpeg')
        })
      } catch (e) {
        console.error(e)
        this.$toast.error('Error when trying to download. Please try again later.')
        Bugsnag.notify(e)
      } finally {
        this.downloading = false
        this.$gtag.event('download_timetable', { value: 1 })
      }
    }
  }
}
</script>

<style scoped>
.fallHeader { border-color: var(--v-fall-base); }
.winterHeader { border-color: var(--v-winter-base); }
.summerHeader { border-color: var(--v-summer-base); }

.calendarSheet {
  min-width: 500px;
  overflow-x: auto;
}

.roundedCorner {
  border-radius: 6px;
}

>>>div.v-event-timed-container {
  margin: 0;
}
>>>.v-calendar-daily_head-day-label {
  display: none;
}
>>>.v-calendar-daily_head-weekday {
  padding: 15px;
  font-weight: bold;
  font-size: 14px;
  color: var(--v-text-base) !important;
}
>>>.v-calendar-daily__intervals-body {
  border-bottom:  1px solid var(--v-grey-base);
}
>>>.theme--dark.v-calendar-events .v-event-timed {
  border: 1px solid var(--v-background-base) !important;
}
.theme--dark.v-calendar-daily {
  background-color: var(--v-background-base);
}
>>>.v-calendar-daily__intervals-body {

}

</style>
