<template>
  <div class="px-3" style="height: 100%; overflow-y: auto; background: var(--v-background-base)">
    <div v-if="asyncEvents.length > 0">
      <div class="my-2" style="margin-left: -12px">Asynchronous</div>
      <EventTile
        v-for="event in asyncEvents"
        :key="event.id + event.section + event.start" :event="event"
        @edit="edit"
        @delete="del"
      />
    </div>

    <div class="my-4" style="position: relative;" v-for="entry of bucketMapping" :key="entry[0]">
      <div class="my-2 flex flex-row" style="align-items: baseline;">
        <div style="margin-left: -12px" class="font-bold">{{entry[0]}}</div>
        <div v-if="buckets[entry[1]].length === 0" class="mt-1 ml-2 font-italic text-caption text-center" style="opacity: 50%; position: absolute; left: 0; right: 0;"> No courses </div>
      </div>
      <div v-if="buckets[entry[1]].length > 0">
        <EventTile
          v-for="event in buckets[entry[1]]"
          :key="event.id + event.section + event.start" :event="event"

          :editable="editable"
          :locked="isLocked(event)"
          @edit="edit"
          @delete="del"
          @lockToggle="lockToggle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getUnaryConstrainKey, sectionsOverlap } from '@/utils/CSP/constraints'
import EventTile from './EventTile.vue'
import Bugsnag from '@bugsnag/js'

export default {
  components: { EventTile },
  props: ['events', 'lockedSections', 'editable'],
  data () {
    const syncEvents = this.events.filter(e => e.timed && !e.isBlockedTime)

    const bucketMapping = [
      ['Monday', 'MON'],
      ['Tuesday', 'TUE'],
      ['Wednesday', 'WED'],
      ['Thursday', 'THU'],
      ['Friday', 'FRI']
    ]

    // Only show weekend if there
    // exists event on that date
    const hasSaturdayEvent = this.events.findIndex(event => event.day === 'SAT') > -1
    const hasSundayEvent = this.events.findIndex(event => event.day === 'SUN') > -1

    if (hasSundayEvent) {
      bucketMapping.push(['Saturday', 'SAT'], ['Sunday', 'SUN'])
    } else if (hasSaturdayEvent) {
      bucketMapping.push(['Saturday', 'SAT'])
    }

    return {
      asyncEvents: this.events.filter(e => !e.timed),
      buckets: this.generateEventBuckets(syncEvents),

      // The keys are used as headings
      bucketMapping: bucketMapping
    }
  },
  watch: {
    events: function (newEvents, oldEvents) {
      this.asyncEvents = newEvents.filter(e => !e.timed)
      this.buckets = this.generateEventBuckets(newEvents.filter(e => e.timed && !e.isBlockedTime))
    }
  },
  methods: {
    edit (d) { this.$emit('edit', d) },
    del (d) { this.$emit('delete', d) },
    lockToggle (d) { this.$emit('lockToggle', d) },
    isLocked (event) {
      const uniqueId = getUnaryConstrainKey(event.id, event.section)

      return !!this.lockedSections[uniqueId]
    },
    /**
     * Create buckets for MON, TUE, etc. where each
     * event is neatly organized by both day and time.
     */
    generateEventBuckets (events) {
      const buckets = {
        MON: [],
        TUE: [],
        WED: [],
        THU: [],
        FRI: [],
        SAT: [],
        SUN: []
      }

      // Assign conflicts
      for (let i = 0; i < events.length; i++) {
        events[i].start = new Date(events[i].start)
        events[i].end = new Date(events[i].end)

        for (let j = i + 1; j < events.length; j++) {
          if (sectionsOverlap(events[i], events[j]) && events[i].name !== events[j].name) {
            events[i].conflict = true
            events[j].conflict = true
          }
        }
      }

      events.forEach(event => {
        try {
          buckets[event.day].push(event)
        } catch (error) {
          Bugsnag.notify(error)
        }
      })

      // Sort increasing
      for (const day in buckets) {
        buckets[day].sort((ev1, ev2) => ev1.start > ev2.start ? 1 : (ev1.start < ev2.start ? -1 : 0))
      }

      return buckets
    }
  }
}
</script>

<style scoped>

</style>
