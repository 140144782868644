<template>
  <v-container
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
      @click="handleClick"

      @contextmenu="handleRightClick"
      @contextmenu.capture.prevent

      class="pa-0 container"
      :class="{ 'container-hovered': hovered }"

      :data-cy="'block_time_' + event.day + '_' + event.hour"
    >
    <v-col v-if="hovered" class="px-0 py-1 fill-height text-center overflow-ellipsis">
      <p class="font-italic text-body-2"> Block-off time </p>
      <p class="font-italic text-caption mb-0">Right click for more</p>
    </v-col>

    <v-menu
      v-if="optionsMenu.owner === key"
      v-model="optionsMenu.open"

      :position-x="optionsMenu.x"
      :position-y="optionsMenu.y"
      :close-on-content-click="false"

      absolute
      offset-x nudge-top="100" nudge-right="15"
    >
      <v-card data-cy="choose_larger_blocked_time">
        <v-card-title>Block Off Time</v-card-title>
        <v-card-subtitle class="text-body-2">Prevent courses from being automatically <br/>assigned here.</v-card-subtitle>
        <v-card-text class="pb-2">
          <v-row class="pa-3 pb-0">
            <v-select data-cy="larger_block_time_start" class="mx-2 select-time" dense v-model="selected.startHour" :items="allowedHours">
              <template v-slot:item="data">
                <div :data-cy="`start_${textFor(data)}`">{{ textFor(data) }}</div>
              </template>

              <template v-slot:selection="data">
                <div data-cy="larger_block_time_start">{{ textFor(data) }}</div>
              </template>
            </v-select>
            <v-select data-cy="larger_block_time_end" class="mx-2 select-time" dense v-model="selected.endHour" :items="allowedHours">
              <template v-slot:item="data">
                <div :data-cy="`end_${textFor(data)}`">{{ textFor(data) }}</div>
              </template>

              <template v-slot:selection="data">
                <div data-cy="larger_block_time_end">{{ textFor(data) }}</div>
              </template>
            </v-select>
          </v-row>
          <v-row v-if="hasError" class="px-3">
            <v-alert data-cy="large_block_time_error" class="text-body-2" type="error" border="left" dense text>Start time must be before end time!</v-alert>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn small text color="accent" @click="close">Cancel</v-btn>
          <v-btn small color="accent" depressed @click="save" class="px-3" :disabled="hasError" data-cy="save_block_time">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-container>
</template>

<script>
import { deepClone } from '@/utils/shared/helpers'

export default {
  props: ['event', 'optionsMenu'],

  data () {
    const arrayRange = []

    // Between 8AM and 10PM
    for (let i = 8; i < 24; i++) arrayRange.push(i)

    return {
      hovered: false,

      // List selectable times in the day, 24hr format
      allowedHours: arrayRange,

      selected: {
        startHour: this.event.hour,
        endHour: this.event.hour + 1
      }
    }
  },
  computed: {
    key () { return this.event.date + this.event.time },
    hasError () { return this.selected.startHour >= this.selected.endHour }
  },
  methods: {
    reset () {
      this.selected.startHour = this.event.hour
      this.selected.endHour = this.event.hour + 1
    },
    handleClick () {
      this.$emit('blockTime', deepClone({
        day: this.event.day,
        startHour: this.selected.startHour,
        endHour: this.selected.endHour
      }))

      this.reset()
    },
    handleRightClick (mouseEvent) {
      this.optionsMenu.open = false
      this.optionsMenu.owner = this.key
      this.optionsMenu.x = mouseEvent.clientX
      this.optionsMenu.y = mouseEvent.clientY

      // Needed to smooth out moving the menu on re-right-click
      this.$nextTick(() => {
        this.optionsMenu.open = true
      })
    },
    close () {
      this.optionsMenu.owner = ''
      this.optionsMenu.open = false
      this.reset()
    },
    save () {
      this.handleClick()
      this.close()
    },
    textFor ({ item }) {
      return item > 12 ? `${item % 12}:00 PM` : (item < 12 ? `${item}:00 AM` : '12:00 PM')
    }
  }
}
</script>

<style scoped>
.select-time {
  width: 25px;
}

p {
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}

.container {
  height: 100%;
  cursor: pointer;
}
.container-hovered {
  background: #D9D9D9;
}

.col-container {
  height: 100%;

}
</style>
