<template>
  <!-- A tile to render events, used in mobile timetable -->
  <v-row class="my-1 text-body-2">
    <v-sheet width="25%" style="background: var(--v-background-base)">
      <div style="opacity: 50%;">
        {{getTimeString(event.start)}}
      </div>

      <v-chip dark color="redText" label small v-if="event.conflict" class="mb-2">
        Conflict
      </v-chip>

      <v-chip label small v-if="locked">
        Locked
      </v-chip>
    </v-sheet>

    <v-sheet
      class="px-3 py-1 "
      :style="textStyle"
      rounded
      :color="event.color"
      :width="event.timed ? '75%' : '100%'"
    >
      <div class="font-weight-bold flex flex-row">
        {{ courseCode }} • {{ section }}

        <v-spacer />
        <v-menu v-if="editable" bottom left offset-x transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                style="margin-right: -5px;"
              >
                <v-icon :style="textStyle">mdi-dots-vertical</v-icon>
              </v-btn>
          </template>

          <v-list dense>
            <v-list-item v-if="event.timed" @click="$emit('lockToggle', event)">
              <v-list-item-icon class="mx-2">
                <v-icon small> {{ locked ? 'mdi-lock' : 'mdi-lock-open-outline' }} </v-icon>
              </v-list-item-icon>
              <v-list-item-title> {{ locked ? 'Unlock' : 'Lock' }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="$emit('edit', { event })">
              <v-list-item-icon class="mx-2">
                <v-icon small>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>

            <v-list-item @click="$emit('delete', { event })">
              <v-list-item-icon class="mx-2">
                <v-icon color="redText" small>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div v-if="event.timed"> {{ getTimeString(event.start) }} - {{ getTimeString(event.end) }} </div>
      <div class="flex flex-row mt-2" style="align-items: center;">
        <div>{{ (event.location && event.location.room) ? event.location.room : '' }}</div>
      </div>
    </v-sheet>
  </v-row>
</template>

<script>
export default {
  props: ['event', 'locked', 'editable'],
  data () {
    const eventNameSegments = this.event.name.split('•').map(segment => segment.trim())
    return {
      courseCode: eventNameSegments[0],
      section: eventNameSegments[1].split('-')[1].trim()
    }
  },
  computed: {
    textStyle () {
      const color = this.event.color
      return `color: var(--v-${color}Text-base)`
    }
  },
  methods: {
    getTimeString (date) {
      if (!this.event.timed) return ''

      let suffix = 'a.m.'
      let hours = date.getHours()
      const minutes = date.getMinutes()

      if (hours > 12) {
        hours = hours % 12
        suffix = 'p.m.'
      }

      return `${hours}:${minutes > 9 ? minutes : minutes + '0'} ${suffix}`
    }
  }
}
</script>

<style scoped>

</style>
