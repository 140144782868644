<template>
  <div v-if="!hasCoursesChosen">
    <div v-if="scheduleExists || canEditTimetable" class="my-12 py-12">
      <v-row justify="center"><v-icon color="accent" class="calendarIcon py-6">mdi-calendar</v-icon></v-row>
      <p class="text-h5 text-md-h4 text-lg-h4 text-center">Add a course to get started!</p>
      <p class="text--secondary text-body-1 text-center">Quickly generate conflict-free timetables for any semester.</p>
    </div>
    <div v-else-if="loading" class="my-12 py-12">
      <v-row justify="center"><v-progress-circular indeterminate size="64" width="6" color="accent" class="py-12 my-12"/></v-row>
      <p class="text-h5 text-md-h4 text-lg-h4 text-center">Retrieving the timetable ...</p>
      <p class="text--secondary text-body-1 text-center">Hang tight while we look for the requested timetable.</p>
    </div>
    <div v-else class="my-12 py-12">
      <v-row justify="center"><v-icon color="accent" class="calendarIcon py-6">mdi-calendar-question</v-icon></v-row>
      <p class="text-h5 text-md-h4 text-lg-h4 text-center">We couldn't find the timetable...</p>
      <p class="text--secondary text-body-1 text-center">Please check the URL is correct.</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    hasCoursesChosen: { type: Boolean },
    scheduleExists: { type: Boolean },
    loading: { type: Boolean },
    canEditTimetable: { type: Boolean }
  }
}
</script>

<style scoped>
.calendarIcon {
  font-size: 75px;
}
</style>
