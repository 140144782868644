import { WEEK_START } from '../timetable/constants'
import { timetableTimeToDateRange } from '@/utils/shared/timetableDateTime'

export const COURSE_COLOURS = [
  'red',
  'orange',
  'blue',
  'green',
  'purple',
  'mint',
  'pink',
  'yellow',
  'lilac',
  'utsg',
  'utsc',
  'utm'
  // '#FF1744',
  // '#D81B60',
  // '#4A148C',
  // '#673AB7',
  // '#311B92',
  // '#1A237E',
  // '#0D47A1',
  // '#1578CF',
  // '#03A9F4',
  // '#7986CB',
  // '#FF8F00',
  // '#E65100',
  // '#DC143C',
  // '#E91E63',
  // '#02C867'
]

export function isLectureFallSection (lec) {
  if (typeof lec === 'string') return lec.startsWith('F')
  return lec.section.startsWith('F')
}

export function isLectureWinterSection (lec) {
  if (typeof lec === 'string') return lec.startsWith('W')
  return lec.section.startsWith('W')
}

export function isLectureSummerSection (lec) {
  if (typeof lec === 'string') return lec.startsWith('S')
  return lec.section.startsWith('S')
}

export function isSectionOnline (sec) {
  if (typeof sec === 'string') return sec.startsWith('Online')
  return (sec.delivery || '').startsWith('Online')
}

export function isSectionAsync (sec) {
  if (typeof sec === 'string') return sec.startsWith('Asynchronous')
  return (sec.delivery || '').startsWith('Asynchronous') || sec.times.length < 1
}

/**
 *
 * @param {any} sec
 * @returns {boolean} True iff given section represents a lecture,
 * false otherwise.
 */
export function isSectionLecture (sec) {
  let secName = sec
  if (typeof sec !== 'string') secName = sec.section

  return secName.split('-')[1].trim().startsWith('LEC')
}

/** Gets the year for the section.
 * @example getYearFromSection('F21 - LEC 1') => 2021
 */
export function getYearFromSection (sec) {
  return parseInt('20' + sec.section.split('-')[0].trim().slice(1))
}

/** Pick random starting point */
let lastIndex = Math.floor(Math.random() * COURSE_COLOURS.length)
export function getRandomColour () {
  const colour = COURSE_COLOURS[lastIndex % COURSE_COLOURS.length]
  lastIndex += 1

  return colour
}

/**
 * @param courseID {string} ID of the course
 * @param courseData {any} The entire course object
 * @param sectionType {string} Which section to target, e.g. 'lecture' or 'tutorial
 * @param target {any[]} The array to put the created events in
 * @param opts {{ isTemporary: boolean }} Additional options to customize the event
 */
export function createEventsForSection (courseID, courseData, sectionType, target, opts = {}) {
  const section = courseData[sectionType]
  const isTimed = !isSectionAsync(section)

  const baseEvent = {
    id: courseID,
    name: `${courseID} • ${section.section}`,

    start: WEEK_START.MON,
    end: WEEK_START.FRI,
    timed: isTimed,

    isBlockedTime: !!courseData.isBlockedTime,
    isTemporaryTime: !!opts.isTemporary,
    color: courseData.color,

    ...section
  }

  if (isTimed) {
    for (const time of courseData[sectionType].times) {
      const { day, start, end, location } = time
      const event = {
        ...baseEvent,
        start: new Date(`${WEEK_START[day]} ${start.getHours()}:${start.getMinutes()}`.replace(/-/g, '/')),
        end: new Date(`${WEEK_START[day]} ${end.getHours()}:${end.getMinutes()}`.replace(/-/g, '/')),
        location,
        day
      }
      target.push(event)
    }
  } else {
    target.push(baseEvent)
  }
}

/**
 * Create calendar events for v-calendar from given lecture/tutorial data
 * @param {*} section
 */
export function createCalendarEventsFromSectionData (sectionData) {
  let course
  const events = []

  for (const courseID in sectionData) {
    course = sectionData[courseID]
    createEventsForSection(courseID, course, 'lecture', events)
    if (course.tutorial) createEventsForSection(courseID, course, 'tutorial', events)
  }

  return events
}

/**
 * When getting data back from the graphql timetable endpoint,
 * we need to fix the types of some data.
 *
 * Note: This WILL mutate the passed in object!
 * */
export function sanitizeCourseData (course) {
  sanitizeCourseSection(course.lecture)
  sanitizeCourseSection(course.tutorial)

  if (Array.isArray(course.lec)) {
    course.lec.forEach(lecture => sanitizeCourseSection(lecture))
  }

  if (Array.isArray(course.tut)) {
    course.tut.forEach(tutorial => sanitizeCourseSection(tutorial))
  }
}

export function sanitizeCourseSection (section) {
  if (!section) return

  section.times.forEach(tut => {
    const { start, end } = timetableTimeToDateRange(tut)
    tut.start = start
    tut.end = end
  })
}
