<template>
  <v-card>
    <v-list>
      <v-list-item>
        <v-checkbox v-model="opts.avoidConflicts" label="Avoid Conflicts" color="accent" style="width: 100%" />
      </v-list-item>

      <v-list-item class="mb-2">
        <v-checkbox v-model="opts.onlineOnly" label="Online Only" color="accent" style="width: 100%" />
      </v-list-item>

      <v-divider class="my-2" />

      <v-list-item class="mb-4">
        <v-col class="px-0">
          <p class="mt-4 mb-2 text-body-2"> Time Preference </p>
          <v-btn-toggle v-model="opts.timeOfDayPreference" rounded mandatory dense color="accent">
            <v-btn v-for="(time, idx) of allowedTimePreferences" :key="idx" class="text-capitalize" small> {{ time }} </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-list-item>

      <v-list-item class="mt-6">
        <v-btn @click="$emit('clearSemester')" class="text-capitalize" block small depressed color="error" :ripple="false" data-cy="clear_semester_btn">
          <v-icon color="white" size="22" class="mr-2">mdi-delete</v-icon><span class="white--text">Clear</span>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { TIME_OF_DAY_PREFERENCES } from '@/utils/timetable'
export default {
  props: ['opts', 'scheduleMeta'],
  data () {
    return {
      allowedTimePreferences: TIME_OF_DAY_PREFERENCES
    }
  }
}
</script>

<style scoped>
p {
  margin: 0;
}
>>>div.v-list-item {
  height: 25px;
}
</style>
