export const WEEKDAY_TO_DAY_MAPPING = {
  1: 'MON',
  2: 'TUE',
  3: 'WED',
  4: 'THU',
  5: 'FRI'
}

export const TIME_OF_DAY_PREFERENCES = ['Early', 'Balanced', 'Late']

/** Misc. helpers for timetable related tasks. */

/**
 * Create a dummy course entry given start and end time.
 * Each entry generated will have a unique ID as the course code.
 *
 */
export function createBlockedOffEvent (day, startHour, endHour, section = 'F99 - Blocked') {
  let startSuffix = 'AM'
  let endSuffix = 'AM'

  if (startHour > 12) {
    startHour = startHour - 12
    startSuffix = 'PM'
  }

  if (endHour > 12) {
    endHour = endHour - 12
    endSuffix = 'PM'
  }

  const lec = {
    section,
    times: [{
      day: WEEKDAY_TO_DAY_MAPPING[day],
      time: `${startHour}:00 ${startSuffix} - ${endHour}:00 ${endSuffix}`
    }]
  }

  const uniqueId = section + Math.random() // Small chance for collision...

  return {
    data: {
      courseResult: {
        code: uniqueId
      },
      timetableResult: {
        timetable: {
          code: uniqueId,
          lec: [lec],
          tut: []
        }
      }
    },
    lecture: lec,
    tutorial: null,
    isBlockedTime: true
  }
}
