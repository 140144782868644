<template>
  <div class="ml-2 pb-5" data-cy="edit_timetable_drawer">
    <v-row @mouseleave="$emit('preview', null)" v-for="(courses, sem, idx) in timetableOverview" :key="idx + 'B'" class="mx-0">
      <v-col v-if="test(sem)" class="pb-0 pl-0 pr-1">
        <p v-if="showHeader" class="mb-1 font-weight-medium">{{ sem }}</p>

        <v-expansion-panels flat>
          <v-expansion-panel
            v-for="(course, idx) in courses" :key="idx + '_' + course.code + course.year + course.semester"
            style="margin: 3px 0; border-radius: 0;"
            active-class="expansion-panel-container"

            @click="handleClick(sem, idx)"
          >
            <SectionListing
              :ref="`sectionListing_${sem}_${idx}`"

              :course="course"
              :courseChosen="courseForSemesterAndCode(sem, course.code)"
              :canEdit="canEdit"
              :allowPreview="allowPreview"

              :onPreview="handlePreviewed"
              :onUpdate="handleUpdateCourse"
            />
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SectionListing from '@/components/timetable/controls/SectionListing'

export default {
  components: { SectionListing },
  props: {
    showHeader: { type: Boolean, default: true },
    allowPreview: { type: Boolean, default: true },

    timetableOverview: { },
    canEdit: { type: Boolean, default: false },

    name: { type: String },
    width: { type: String, default: null },

    /** Which semesters the user is currently viewing */
    semestersChosen: { type: Array, default: () => ['fall', 'winter', 'summer'] },

    // Keep track of which section already chosen
    fallCourses: { },
    winterCourses: { },
    summerCourses: { }
  },
  data () {
    return {
      nameChosen: this.name,
      collapsed: false,
      desiredWidth: this.width
    }
  },
  mounted () {
    this.nameChosen = this.name
  },
  methods: {
    test (sem) {
      const semester = sem.split(' ')[0].toLowerCase()
      return this.semestersChosen.includes(semester)
    },
    toggleCollapse () {
      this.$gtag.event('toggle_fullscreen_edit_panel_collapse', { value: 1 })
      if (this.collapsed) this.desiredWidth = this.width
      else this.desiredWidth = '25px'

      this.collapsed = !this.collapsed
    },
    handlePreviewed (val) {
      if (val) this.$gtag.event(`edit_panel_preview_section_${val.code}`, { value: 1 })
      this.$emit('preview', val)
    },
    handleUpdateCourse (courseData) {
      this.$gtag.event(`edit_panel_select_section_${courseData.code}`, { value: 1 })

      this.$emit('updated', courseData)
    },
    handleClick (semester, index) {
      const refId = `sectionListing_${semester}_${index}`
      this.$refs[refId][0].getData()
    },
    /**
     * Get course for given semester and code.
     *
     * @param sem {string} E.g. "Fall 2022"
     * @param code {string} E.g. "CSCA08H3
     * */
    courseForSemesterAndCode (sem, code) {
      switch (sem[0]) {
        case 'F': return this.fallCourses[code]
        case 'W': return this.winterCourses[code]
        case 'S': return this.summerCourses[code]
      }
    }
  }
}
</script>

<style scoped>

>>>.v-expansion-panel-content__wrap {
  padding: 0;
}

.v-expansion-panel {
  /*height: 35px;*/
}

.expansion-panel-container {
  height: unset;
}
>>>.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: var(--v-background-base);
}
>>>.v-text-field__details {
  padding-top: 5px;
}
>>>.fabBtn:focus::before {
  opacity: 0 !important;
}
</style>
