<!-- Allow user to hover over section timings -->
<template>
  <div>
    <v-expansion-panel-header ripple class="pa-0 pl-4 text-body-2" :data-cy="`section_listing_${course.code}`">
      <v-row align="center" class="pr-5" style="order: 0">
        <v-chip small label :color="course.colour" :text-color="course.colour + 'Text'" class="font-weight-bold px-2 mr-2">{{ course.code.slice(3, 6) }}</v-chip>
        <p class="ml-0 text--secondary">{{ course.code }}</p>

        <v-spacer/>
          <!-- If the section is outdated, i.e. was deleted or renamed -->
          <v-tooltip top color="warning">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="hasOutdatedSection()" v-bind="attrs" v-on="on" class="mr-2" size="20" color="warning">mdi-alert</v-icon>
          </template>
          <span v-if="hasOutdatedSection()">{{ outdatedSectionText }}</span>
        </v-tooltip>

        <p style="font-size: 9pt;" class="ml-auto text--secondary"> {{lecSection}} </p>
        <p v-if="tutSection" style="font-size: 9pt;" class="ml-1 text--secondary"> • {{tutSection}} </p>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content style="color: var(--v-text-base); background-color: var(--v-background-base)">
      <p v-if="loading" class="text-center">
        <v-progress-circular indeterminate :size="25" :width="3" color="accent" />
      </p>
      <v-col v-else class="px-0 text-start">
        <h4 class="ml-2 text-body-2 font-weight-medium">Lectures</h4>

        <SectionListingItem
          type="lecture"
          :sections="courseData.lectures"
          :courseChosen="courseChosen"
          :canEdit="canEdit"
          :allowPreview="allowPreview"

          @handlePreview="handlePreview($event.type, $event.section)"
          @handleUpdateCourse="handleUpdateCourse($event.type, $event.section)"

          data-cy="section_listings_lectures"
        />

        <h4 v-if="courseData.tutorials.length > 0" class="ml-2 mt-2 text-body-2 font-weight-medium">Tutorials</h4>
        <SectionListingItem
          type="tutorial"

          :sections="courseData.tutorials"
          :courseChosen="courseChosen"
          :canEdit="canEdit"
          :allowPreview="allowPreview"

          @handlePreview="handlePreview($event.type, $event.section)"
          @handleUpdateCourse="handleUpdateCourse($event.type, $event.section)"

          data-cy="section_listings_tutorials"
        />
      </v-col>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import SectionListingItem from '@/components/timetable/controls/SectionListingItem'

export default {
  components: { SectionListingItem },
  props: [
    'course',
    'courseChosen',
    'onPreview',
    'onUpdate',
    'canEdit',
    'allowPreview'
  ],
  data () {
    return {
      loading: false,
      courseData: {
        lectures: [],
        tutorials: []
      },
      cached: false
    }
  },
  computed: {
    lecSection () { return this.courseChosen?.lecture.section.split('-').at(1).trim() },
    tutSection () { return this.courseChosen?.tutorial?.section.split('-').at(1).trim() },
    outdatedSectionText () {
      // TODO: Check practical as well?
      const section = this.courseChosen.lecture?.outdated ? this.courseChosen.lecture.section : this.courseChosen.tutorial.section
      return `The section "${section}" no longer exists.`
    }
  },
  methods: {
    hasOutdatedSection () {
      return this.courseChosen.lecture?.outdated || this.courseChosen.tutorial?.outdated
    },
    handlePreview (type, section) {
      const { code, semester } = this.course

      // Create fake course
      const previewCourseData = {
        code: '*' + code, // Needs to be unique! Course with this code already exists that's why...
        semester,
        color: this.courseChosen.color,
        isPreview: true
      }

      // Keep it generic for lecture/tutorial/practical/etc.
      previewCourseData[type] = section

      this.onPreview(previewCourseData)
    },
    handleUpdateCourse (type, section) {
      const { code, semester } = this.course

      this.onUpdate({
        code,
        semester,
        type,
        section
      })

      this.onPreview(null)
    },
    async getData () {
      // Prevent spamming
      if (this.loading || this.cached) return
      this.loading = true

      const q = {
        query: 'query getTimetable($course: String!, $sem: String, $year: String) { ' +
          'getTimetable(course: $course, sem: $sem, year: $year) { timetable, filters } }',
        variables: { course: this.course.code, sem: this.course.semester, year: this.course.year },
        operationName: 'getTimetable'
      }

      const response = await fetch('/graphql', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(q)
      })

      if (!response.ok) {
        this.$toast.error(`Could not get timetable info for course: ${this.course.code}`)
        this.loading = false
        return
      }

      const { data: { getTimetable: { timetable } } } = await response.json()

      // TODO: Add support for practicals?
      this.courseData.lectures = timetable.lec
      this.courseData.tutorials = timetable.tut

      this.cached = true
      this.loading = false
    }
  }
}
</script>

<style scoped>
p {
  margin: 0;
}

.disabled-item {
  opacity: 0.5;
  pointer-events: none;
}

.v-expansion-panel-header {
  height: 35px;
}
</style>
