<template>
  <v-btn
      @click="$emit('toggle')" icon x-small
      class="ma-0"
      :class="{ 'hidden-soft': hidden }"
      style="margin-right: 0;"
  >
    <v-icon :style="styling">
      {{ locked ? 'mdi-lock' : 'mdi-lock-open-outline' }}
    </v-icon>
  </v-btn>
</template>
<script>
export default {
  props: {
    hidden: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean
    },
    styling: {
      type: String,
      default: ''
    }
  }
}
</script>
