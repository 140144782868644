<template>
  <v-container class="contentContainer" :class="{ 'px-4': onMobile }" style="padding: 10px 10px 10px 25px">
    <ExitModal
      :model="exitModal.show"
      title="Unsaved Changes"
      body="Are you sure you wish to exit? Any un-saved changes will be lost!"

      @cancel="handleExit(false)"
      @ok="handleExit(true)"
    />

    <!-- TIMETABLE MODAL - Course selection -->
    <v-dialog v-model="timetableModal" :fullscreen="store.app.onMobile" max-width="1100px" @click:outside="showCourses = true">
      <v-card :outlined="store.app.darkMode" class="px-4 py-2" style="border-radius: 5px">
        <v-card-text :class="store.app.onMobile ? 'pa-6 mb-12' : 'pa-1'">
          <CourseOfferings :course="courseSelected" :make-selection="makeSelection" :default-semester="semesterSelected"
                           :courseColor="courseSelectedColor" :reset="timetableModal"
                           modal show-stats enable-selection course-heading @update="handleUpdate($event)"/>
        </v-card-text>
        <v-card-actions :class="store.app.onMobile ? 'fixedBottom' : 'py-3'">
          <v-spacer/>
          <v-btn color="accent" class="px-4 mr-2" outlined @click="timetableModal = false; showCourses = true" data-cy="cancel-add-course-btn">
            Cancel
          </v-btn>
          <v-btn color="accent" depressed class="px-6" @click="makeSelection = true" data-cy="add-course-btn">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Header -->
    <v-row class="px-3" align="center">
      <p class="pageHeading headingSize">Timetable Builder</p>
    </v-row>

    <v-col v-if="canEditTimetable">
      <v-row class="mt-4 mb-2 px-0 px-sm-3">
        <SearchAndFilter
            dynamic-list
            searchCols="6"

            :justify="onMobile ? 'end' : 'start'"
            :featured="$vuetify.breakpoint.xl ? undefined : []"
            :showAlphas="false"
            :showFilterChips="$vuetify.breakpoint.xlOnly"
            :search-only="onMobile"

            @selected="handleCourseSelected($event)"
            @toggle="viewingFilters = $event"
        />
      </v-row>

        <!-- Mobile Header-->
      <template v-if="onMobile">
        <v-row class="mt-5">
          <v-btn
              v-if="canSaveTimetable"
              @click="saveTimetable(name)"

              depressed
              :loading="loading.saveTimetable"
              :text="!timetableHasChanges"
              :disabled="!timetableHasChanges"
              class="mr-1 text-capitalize w-half"
              data-cy="save_timetable_btn"
          >
            <v-icon class="mr-3" color="accent">mdi-content-save</v-icon>
            Save
          </v-btn>
          <v-spacer/>
          <v-menu v-if="store.user.userInfo && onMobile" bottom offset-y left close-on-content-click>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" color="accent" text depressed class="w-half text-capitalize" data-cy="my_timetables_dropdown">
                <v-icon class="pr-2">mdi-calendar</v-icon>My Timetables
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(schedule, idx) in schedules" :key="idx" :disabled="scheduleMeta.id === schedule._id"
                           @click="$router.push({ query: { id: schedule._id }})">{{ schedule.name }}
              </v-list-item>
              <v-divider v-if="schedules.length < 12"/>
              <v-list-item v-if="schedules.length < 12" class="font-weight-medium" @click="newTimetable()" data-cy="create_new_timetable">
                <v-icon class="mr-3" color="text">mdi-plus</v-icon>Create new timetable
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>

        <v-row>
          <SemesterToggle
              classes="mt-5 w-full"

              :semesterToggles="semesterToggles"
              :semestersEnabled="semestersEnabled"
              :isChosen="_semChosen"
              @toggle="toggleSemester($event)"
          />
        </v-row>
      </template>
    </v-col>

    <v-navigation-drawer v-if="$vuetify.breakpoint.mdAndUp" right app permanent width="350" color="background">
      <span slot="prepend">
        <v-tabs v-model="editPanelTab" color="accent" fixed-tabs height="56" background-color="background">
          <v-tab :ripple="false" class="text--text text-capitalize">Details</v-tab>
          <v-tab :ripple="false" v-if="store.user.userInfo" class="text--text text-capitalize">My Timetables</v-tab>
        </v-tabs>
        <v-divider/>
      </span>

      <v-tabs-items v-model="editPanelTab">
        <v-tab-item>
          <v-sheet
              v-if="!onMobile"
              rounded
              class="relative shadow px-1 py-2"
              style="display: flex; flex-direction: column; height: 100vh; width: 350px;"
              color="background"

              data-cy="edit_timetable"
          >
            <v-text-field
                :readonly="!canEditTimetable"
                :solo="!canEditTimetable"
                dense
                flat
                solo-inverted
                background-color="border"
                class="grow-0 mt-2"
                :class="{ 'px-2': canEditTimetable }"
                v-model="scheduleMeta.name"
                style="font-size: 1rem;"
                data-cy="timetable_title"
                label="Timetable Name"

                hide-details
            />

            <v-row v-if="canEditTimetable && canSaveTimetable && scheduleExists" class="grow-0 py-1 px-5 my-2 nowrap">
              <!-- Save Timetable -->
              <v-btn
                  v-if="canSaveTimetable"
                  @click="saveTimetable(name)"

                  depressed
                  :loading="loading.saveTimetable"
                  :text="!timetableHasChanges"
                  :disabled="!timetableHasChanges"
                  class="mr-1 text-capitalize grow"
                  data-cy="save_timetable_btn"
              >
                <v-icon class="mr-3" color="accent">mdi-content-save</v-icon>
                Save
              </v-btn>

              <v-menu v-if="scheduleExists" :open-on-hover="!onMobile" bottom offset-y nudge-left="50"  :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" class="px-2 ml-1 grow text-capitalize" data-cy="share_timetable_btn">
                    <v-icon class="mr-1" color="accent">mdi-share</v-icon>
                    Share
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <ShareTimetable :scheduleMeta="scheduleMeta" :loading="loading.scheduleShared" @toggleSharedStatus="toggleSharedStatus()"/>
              </v-menu>
            </v-row>

            <!-- Save & Share -->
            <v-row v-if="!scheduleMeta.id && hasCoursesChosen()" class="grow-0 mt-3 mb-1 px-4">
              <v-btn text data-cy="save_and_share_timetable_btn"
                     @click="saveTimetable()" block class="shrink text-capitalize">
                <v-icon color="accent" class="mr-3">mdi-content-save</v-icon>Save & Share
              </v-btn>
            </v-row>

            <v-divider class="my-2" />
            <v-row v-if="canEditTimetable && !onMobile" class="px-5 mt-1 mb-3 grow-0">
              <LoadTimetable
                block
                class="w-full"

                :existingFallCourses="fallCoursesChosen"
                :existingWinterCourses="winterCoursesChosen"
                :existingSummerCourses="summerCoursesChosen"

                @selected="handleBulkCoursesSelected($event)" open-modal
              />
            </v-row>

            <!-- Copy Timetable -->
            <v-row v-if="!canEditTimetable && scheduleMeta.id && scheduleExists" class="grow-0 mt-2 mb-3 px-4">
              <v-btn block text data-cy="copy-timetable-btn" @click="saveTimetable(null, true)">
                <v-icon color="accent" left>mdi-content-copy</v-icon>Copy Timetable
              </v-btn>
            </v-row>

            <template v-if="showSemesterToggle() && !fullScreen">
              <v-divider />
              <SemesterToggle
                classes="pl-5 grow-0"

                :semesterToggles="semesterToggles"
                :semestersEnabled="semestersEnabled"
                :isChosen="_semChosen"
                @toggle="toggleSemester($event)"
              />
            </template>

            <v-divider class="mb-2" />

            <EditTimetable
                class="overflow-y-auto pb-3"
                style="min-height: 100px;"

                v-if="hasCoursesChosen"
                :timetableOverview="timetableOverview"

                :name="name"
                :show="true" :temporary="false" :preventClose="true"
                :canEdit="canEditTimetable"

                :fallCourses="fallCoursesChosen"
                :winterCourses="winterCoursesChosen"
                :summerCourses="summerCoursesChosen"
                :semestersChosen="semestersChosen"

                :scheduleExists="scheduleExists"

                @preview="courseHovered = $event"
                @updated="handleCourseUpdate"
            />
          </v-sheet>
        </v-tab-item>
        <v-tab-item>
          <v-list color="background">
            <v-list-item v-for="(schedule, idx) in schedules" :key="idx" :disabled="scheduleMeta.id === schedule._id"
                         @click="$router.push({ query: { id: schedule._id }})">
              {{ schedule.name }}
              <v-spacer/>
              <p class="text--secondary text-caption mb-0 ml-3">{{ new Date(schedule.updatedAt).toDateString() }}</p>
            </v-list-item>
            <v-divider v-if="schedules.length < 24"/>
            <v-list-item v-if="schedules.length < 24" @click="newTimetable()" data-cy="create_new_timetable">
              <v-icon class="mr-3" color="accent">mdi-plus-thick</v-icon>Create new timetable
            </v-list-item>
          </v-list>
        </v-tab-item>
      </v-tabs-items>
    </v-navigation-drawer>

    <!-- MAIN CONTENT AREA - Content Section -->
    <v-row justify="center" :class="onMobile ? 'mx-0' : 'mr-0'">
      <v-col class="contentCol overflow-hidden" :class="{ 'fullScreenContentCol px-3': fullScreen }">
        <!-- Messages for when timetable doesn't exist -->
        <LoadingMessages
            :hasCoursesChosen="hasCoursesChosen()"
            :scheduleExists="scheduleExists"
            :canEditTimetable="canEditTimetable"
            :loading="loading.timetable"
        />

        <!-- CALENDARS -->
        <v-row v-if="hasCoursesChosen() || (enableUIComponents && !fullScreen)" class="mt-1 pa-0 nowrap">
          <!-- Fall calendar -->
          <TimetableCalendar
            id="fallCalendar"
            :class="{ 'hidden': !fallChosen || !fallEnabled() }"
            :height="fullScreen ? '100%' : null"
            variant="fall"
            :events="fallEvents"
            :lockedSections="lockedSections"
            :canEditTimetable="canEditTimetable"

            :scheduleMeta="scheduleMeta"
            :scheduleExists="scheduleExists"

            :enableUIComponents="enableUIComponents"
            :filterOptsMenuOpen="filterOptsMenuOpen.fall"
            :generatorOptions="fallGeneratorOptions"
            :loading="loading"
            :year="'Fall ' + fallYear"

            :dense="dense"
            :height-offset="heightOffset"

            @closeFilterOpts="filterOptsMenuOpen.fall = false"
            @generate="generate('fall')"
            @edit="handleEventClick" @delete="fallDelete" @lockToggle="handleLockToggle"
            @handleBlockTime="handleFallBlockTime" @unblock="fallUnblock"
            @clearSemester="clearSemester('fall',true)"
          />

          <!-- Winter calendar -->
          <TimetableCalendar
            id="winterCalendar"
            :class="{ 'hidden': !(winterChosen && winterEnabled()) }"
            :height="fullScreen ? '100%' : null"
            variant="winter"
            :events="winterEvents"
            :lockedSections="lockedSections"
            :canEditTimetable="canEditTimetable"

            :scheduleMeta="scheduleMeta"
            :scheduleExists="scheduleExists"

            :enableUIComponents="enableUIComponents"
            :filterOptsMenuOpen="filterOptsMenuOpen.winter"
            :generatorOptions="winterGeneratorOptions"
            :loading="loading"
            :year="'Winter ' + winterYear"

            :dense="dense"
            :height-offset="heightOffset"

            @closeFilterOpts="filterOptsMenuOpen.winter = false"
            @generate="generate('winter')"
            @edit="handleEventClick" @delete="winterDelete" @lockToggle="handleLockToggle"
            @handleBlockTime="handleWinterBlockTime" @unblock="winterUnblock"
            @clearSemester="clearSemester('winter',true)"
          />

          <!-- Summer calendar -->
          <TimetableCalendar
            id="summerCalendar"
            :class="{ 'hidden': !(summerChosen && summerEnabled()) }"
            :height="fullScreen ? '100%' : null"
            variant="summer"
            :events="summerEvents"
            :lockedSections="lockedSections"
            :canEditTimetable="canEditTimetable"

            :scheduleMeta="scheduleMeta"
            :scheduleExists="scheduleExists"

            :enableUIComponents="enableUIComponents"
            :filterOptsMenuOpen="filterOptsMenuOpen.summer"
            :generatorOptions="summerGeneratorOptions"
            :loading="loading"
            :year="'Summer ' + summerYear"

            :dense="dense"
            :height-offset="heightOffset"

            @closeFilterOpts="filterOptsMenuOpen.summer = false"
            @generate="generate('summer')"
            @edit="handleEventClick" @delete="summerDelete" @lockToggle="handleLockToggle"
            @handleBlockTime="handleSummerBlockTime" @unblock="summerUnblock"
            @clearSemester="clearSemester('summer',true)"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="needAuthentication" @click:outside="store.errors.resetAuthError()" max-width="425px">
      <v-card :outlined="store.app.darkMode" style="border-radius: 5px; overflow-y: auto" height="650px">
        <SessionModal @loggedIn="needAuthentication = false" css="my-12 pt-8 px-0 overflow-hidden"/>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SearchAndFilter from '@/components/SearchAndFilter'
import CourseOfferings from '@/components/CourseOfferings'
import SessionModal from '@/components/SessionModal'
import ShareTimetable from '@/components/shared/ShareTimetable'
import EditTimetable from '@/components/timetable/controls/EditTimetable'
import TimetableCalendar from '@/components/timetable/TimetableCalendar'
import LoadTimetable from '@/components/timetable/LoadTimetable'

// Helpers
import { createBlockedOffEvent } from '@/utils/timetable'
import { dedupe, deepClone, isEmptyObject } from '@/utils/shared/helpers'
import {
  isLectureFallSection,
  isLectureWinterSection,
  getRandomColour,
  getYearFromSection,
  isLectureSummerSection,
  createCalendarEventsFromSectionData, sanitizeCourseData
} from '@/utils/shared/courses'
import { getUnaryConstrainKey } from '@/utils/CSP/constraints'
import CalendarEventManager from '@/components/timetable/controls/CalendarEventManager'
import Generate from '@/utils/timetable/generate'
import ExitModal from '@/components/shared/ExitModal'
import LoadingMessages from '@/components/timetable/LoadingMessages'
import { InvalidateCSPCache } from '@/utils/timetable/generate-timetables'
import Bugsnag from '@bugsnag/js'
import SemesterToggle from '@/components/timetable/controls/SemesterToggle'
import { useAllStores } from '@/stores/useAllStores'

const CALENDAR_GENERATOR_OPTIONS = {
  avoidConflicts: true,
  onlineOnly: false,
  timeOfDayPreference: 1 /** Balanced */
}

export default {
  name: 'Timetable',
  components: { SemesterToggle, LoadingMessages, ExitModal, ShareTimetable, TimetableCalendar, EditTimetable, SearchAndFilter, CourseOfferings, SessionModal, LoadTimetable },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data () {
    return {
      editPanelTab: 0,
      loading: {
        timetable: true,
        scheduleShared: false,
        saveTimetable: false
      },
      exitModal: {
        show: false,
        callback: null // Function to call when exit allowed by user
      },

      needAuthentication: false,
      eventQueue: { saveTimetable: false, copyTimetable: false },
      hasChanges: false,
      editPanel: false,
      schedules: [],
      showCourses: false,

      scheduleMeta: {
        id: this.$route.query.id,
        name: 'Untitled',
        shared: false,

        createdBy: {
          _id: ''
        },

        created: null,
        updated: null
      },
      editName: false,
      name: '',

      semestersChosen: [],

      /**
       * Which year the fall/winter sections apply to.
       * This lets us figure out whether to render
       * 2021, 2022, 2023, ... etc.
       */
      fallYear: null,
      winterYear: null,
      summerYear: null,

      timetableModal: false,
      makeSelection: false,

      filterOptsMenuOpen: {
        fall: false,
        winter: false,
        summer: false
      },

      fallCoursesChosen: { },
      winterCoursesChosen: { },
      summerCoursesChosen: { },

      /**
       * Map unique course code + section to boolean.
       * True iff section is locked, false otherwise
       */
      lockedSections: { },

      courseSelected: '', /** CourseOfferings modal needs to know which course to show selections for */
      courseSelectedColor: '',

      courseHovered: null, /** The course user is hovering over, in order to see a preview */
      semesterSelected: '', /** Keep track of which sem to open in CourseOfferings component */

      /* So we can make previewed events stand out more */
      querySelectors: {
        fall: '.fallCalendar .timedEvent',
        winter: '.winterCalendar .timedEvent',
        summer: '.summerCalendar .timedEvent'
      },

      /** Semester toggle buttons */
      semesterToggles: [
        { semester: 'fall', icon: ' mdi-leaf', label: 'Fall' },
        { semester: 'winter', icon: ' mdi-snowflake-variant', label: 'Winter' },
        { semester: 'summer', icon: ' mdi-flower-tulip', label: 'Summer' }
      ],

      /** Calendar specific formats */
      fallEvents: [],
      winterEvents: [],
      summerEvents: [],

      /** Calendar controls */
      fallGeneratorOptions: { ...CALENDAR_GENERATOR_OPTIONS },
      winterGeneratorOptions: { ...CALENDAR_GENERATOR_OPTIONS },
      summerGeneratorOptions: { ...CALENDAR_GENERATOR_OPTIONS },

      fullScreen: false,
      heightOffset: -1,
      viewingFilters: false,

      /** User notifications. Ensure only one shown at a time */
      snackbars: {
        unknownError: {
          id: null,
          msg: '[TT 001] Error encountered while generating timetable.'
        },
        unknownError2: {
          id: null,
          msg: '[TT 911] Unknown error creating the timetable.'
        },
        constraintsError: {
          id: null,
          msg: 'Couldn\'t generate a timetable without conflicts! \nTry removing some courses, unblocking time, or allowing in-person sections.'
        }
      }
    }
  },
  /** Handles the case where we go to a DIFFERENT timetable,
   * e.g. timetable/123 to timetable/456.
   * */
  beforeRouteUpdate (to, from, next) { this.handleBeforeTimetableLeave(next) },
  beforeRouteLeave (to, from, next) { this.handleBeforeTimetableLeave(next) },

  async mounted () {
    this.resetCalendar()

    if (this.store.user.userInfo) await this.getSchedules()
    if (this.scheduleMeta.id) {
      await this.loadSchedule()
    } else {
      this.loadCart()
    }

    this.loading.timetable = false

    /**
     * Prompt user if there are any unsaved changes
     * @see https://thewebdev.info/2021/05/22/how-to-watch-multiple-properties-with-a-single-handler-in-vue-js/
     */
    this.$watch(
      (vm) => [
        // Add any more properties to watch, where changes
        // in them can mark a timetable as "unsaved".
        vm.scheduleMeta,

        vm.fallCoursesChosen,
        vm.winterCoursesChosen,
        vm.summerCoursesChosen,

        vm.lockedSections,

        vm.fallGeneratorOptions,
        vm.winterGeneratorOptions,
        vm.summerGeneratorOptions
      ],
      (newVal) => {
        this.hasChanges = true
      },
      {
        immediate: false,
        deep: true
      }
    )

    // So user can easily exist fullscreen
    document.addEventListener('keydown', this.onDocumentKeydown)
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.onDocumentKeydown)
  },
  watch: {
    timetableModal: function (newValue) { if (!newValue) this.semesterSelected = '' },
    '$route.query': function () {
      this.scheduleMeta.id = this.$route.query.id
      this.loadSchedule()
    },
    needAuthentication: function (newValue) {
      if (!newValue && this.store.user.userInfo) {
        if (this.eventQueue.copyTimetable) {
          this.saveTimetable(null, true)
          this.eventQueue.copyTimetable = false
        } else if (this.eventQueue.saveTimetable) {
          this.saveTimetable(this.name)
          this.eventQueue.saveTimetable = false
        }
      }
    },
    courseHovered: function (val, oldVal) {
      let target

      // One of these guaranteed to exist.
      // If not, something went horribly wrong...
      switch ((val || oldVal).semester) {
        case 'F': target = 'fall'; break
        case 'W': target = 'winter'; break
        case 'S': target = 'summer'; break
      }

      if (oldVal) this.removeEventById(oldVal.code, target)

      const selector = this.querySelectors[target]
      const $nexTick = this.$nextTick

      const clearTemporaryStyles = () => {
        document.querySelectorAll(selector).forEach(el => {
          el.style.opacity = 1
          el.style.boxShadow = ''
          el.style.animation = ''
          el.style.zIndex = ''
        })
      }

      const setOpacity = () => {
        clearTemporaryStyles()

        // Hide all existing events
        document.querySelectorAll(selector).forEach(el => {
          const originalCode = val.code.slice(1)

          // Keep only the original course fully visible
          if (!el.textContent.includes(originalCode)) {
            el.style.opacity = '33%'
          } else {
            el.style.opacity = '80%'
          }
        })

        // Show temporary event in a way that stands out
        document.querySelectorAll('.isTemporaryTime').forEach(el => {
          const parent = el.parentElement
          parent.style.animation = ''

          // Fancy styles for the parent element
          parent.style.opacity = '100%'
          parent.style.zIndex = 999
          parent.style.boxShadow = 'rgba(0, 0, 0, 0.35) 0px 5px 15px'

          // Prevent animation from de-synchronizing
          $nexTick(() => {
            parent.style.animation = 'hoverAnimation 1.5s ease-in-out infinite'
          })
        })
      }

      if (val) {
        // Start preview -- want to make all existing events have
        // reduced opacity so that the preview stands much more.
        this.$nextTick(setOpacity)
        this.addCourseToEvents(val, val.code, target, { isTemporary: true })
      }

      // Assumption: No longer previewing anything
      if (oldVal && !val) {
        // Stop preview
        clearTemporaryStyles()
      }
    },
    '$vuetify.breakpoint.width': function () {
      if (this.onlyOneSemesterAllowed() && this.semestersChosen.length > 1) {
        this.semestersChosen = [this.semestersChosen[0]]
      }
    }
  },
  computed: {
    dense () { return this.fullScreen && this.semestersChosen.length > 1 },
    onMobile () { return this.store.app.onMobile },
    onDesktop () { return this.store.app.onDesktop },
    loggedIn () { return this.store.user.userInfo },
    canEditTimetable () {
      if (this.scheduleMeta.id) {
        const createdById = this.scheduleMeta.createdBy?._id
        const userId = this.store.user.userInfo?.id

        return createdById === userId
      }
      return true
    },
    scheduleExists () {
      return !!this.scheduleMeta.id && !!this.scheduleMeta.createdBy?._id
    },
    canSaveTimetable () {
      return this.canEditTimetable && this.scheduleExists
    },
    enableUIComponents () { return !this.loading.timetable && (this.canEditTimetable || !this.scheduleMeta.id) },
    timetableOverview () {
      const courses = {}
      const semMap = { W: 'Winter', S: 'Summer', F: 'Fall' }
      this.store.data.timetableCourses.forEach((course) => {
        if (!course.isBlockedTime) {
          const courseSemesterKey = course.lecture.section[0]
          const year = '20' + course.lecture.section.slice(1, 3)
          const semKey = semMap[courseSemesterKey] + ' ' + year
          if (!(semKey in courses)) courses[semKey] = []

          courses[semKey].push({
            code: course.data.courseResult.code,
            colour: course.color,
            semester: courseSemesterKey,
            year
          })
        }
      })
      return courses
    },
    fallChosen () { return this._semChosen('fall') },
    winterChosen () { return this._semChosen('winter') },
    summerChosen () { return this._semChosen('summer') },
    timetableHasChanges () { return this.hasChanges && !this.loading.timetable }
  },
  methods: {
    log (...args) { console.log('[Timetable]', ...args) },
    debug () { debugger },

    _semChosen (sem) {
      return this.semestersChosen.includes(sem) || this.semestersChosen.includes('all')
    },
    newTimetable () {
      this.$router.push({ query: null })
      this.editPanelTab = 0
    },

    /** Make sure user really wants to exit. */
    handleBeforeTimetableLeave (next) {
      // Make sure user really wants to leave if un-saved changes
      if (this.scheduleExists && this.canEditTimetable && this.hasChanges) {
        this.exitModal.callback = next
        this.exitModal.show = true
      } else {
        // Let them through, either local timetable
        // or they're viewing someone else's

        // Clear the timetable if user was just visiting another saved one.
        if (this.scheduleMeta.id && this.scheduleMeta.createdBy !== this.store.user.userInfo?.username) {
          this.store.data.clearTimetableEntry(null)
        }
        next()
      }
    },

    handleExit (runCallback) {
      this.exitModal.show = false
      if (runCallback) this.exitModal.callback()

      this.exitModal.callback = null
    },

    async loadSchedule () {
      if (!this.scheduleMeta.id) {
        this.store.data.clearTimetableEntry(null)
        this.resetCalendar()
        return
      }
      this.loading.timetable = true
      const resp = await fetch('/rest/v1/schedule/' + this.scheduleMeta.id)

      if (resp.ok) {
        this.resetCalendar()
        const data = await resp.json()

        this.fallGeneratorOptions = data.meta.fallGeneratorOptions
        this.winterGeneratorOptions = data.meta.winterGeneratorOptions
        this.summerGeneratorOptions = data.meta.summerGeneratorOptions

        this.scheduleMeta.name = data.name
        this.scheduleMeta.shared = data.shared
        this.scheduleMeta.createdBy = data.user

        this.scheduleMeta.created = new Date(data.createdAt)
        this.scheduleMeta.updated = new Date(data.updatedAt)

        data.courses.forEach(this.handleCourseSelectionDone)
        this.saveEventsToStore()
        setTimeout(() => {
          this.hasChanges = false
        }, 50)

        this.$gtag.event('timetable_open_schedule', { value: 1 })
      }
      this.loading.timetable = false

      this.editPanelTab = 0
      this.openToFirstAvailableSemester()
    },
    async getSchedules () {
      this.schedules = await (await fetch('/rest/v1/user/mySchedules')).json()
    },
    hasCoursesChosen () {
      return !isEmptyObject(this.fallCoursesChosen) || !isEmptyObject(this.winterCoursesChosen) || !isEmptyObject(this.summerCoursesChosen)
    },
    showSemesterToggle () {
      // At least 2 semesters have courses
      return [
        !isEmptyObject(this.fallCoursesChosen),
        !isEmptyObject(this.winterCoursesChosen),
        !isEmptyObject(this.summerCoursesChosen)
      ].filter(e => e).length > 1
    },
    semestersEnabled (sem) {
      return {
        fall: this.fallEnabled(),
        winter: this.winterEnabled(),
        summer: this.summerEnabled()
      }[sem]
    },
    fallEnabled () { return !isEmptyObject(this.fallCoursesChosen) },
    winterEnabled () { return !isEmptyObject(this.winterCoursesChosen) },
    summerEnabled () { return !isEmptyObject(this.summerCoursesChosen) },

    openToFirstAvailableSemester () {
      if (this.fallEnabled()) this.semestersChosen = ['fall']
      else if (this.winterEnabled()) this.semestersChosen = ['winter']
      else if (this.summerEnabled()) this.semestersChosen = ['summer']
    },
    clearSemester (sem, check = false) {
      const semester = sem.charAt(0).toUpperCase() + sem.slice(1)
      if (!(check && confirm('Are you sure you want to remove all ' + semester + ' courses?'))) return

      this[sem + 'CoursesChosen'] = { }
      this[sem + 'Events'] = []
      this.store.data.clearTimetableEntry(semester[0])

      switch (sem) {
        case 'fall': this.fallEvents = []; break
        case 'winter': this.winterEvents = []; break
        case 'summer': this.summerEvents = []; break
      }

      this.saveEventsToStore()
      this.openToFirstAvailableSemester()
    },

    fallDelete ({ event }) { this._deleteFromSem(event, 'fall') },
    winterDelete ({ event }) { this._deleteFromSem(event, 'winter') },
    summerDelete ({ event }) { this._deleteFromSem(event, 'summer') },
    _deleteFromSem (event, semester) {
      const { id, section } = event
      let target

      switch (semester) {
        case 'fall': target = this.fallCoursesChosen; delete this.fallCoursesChosen[id]; break
        case 'winter': target = this.winterCoursesChosen; delete this.winterCoursesChosen[id]; break
        case 'summer': target = this.summerCoursesChosen; delete this.summerCoursesChosen[id]; break
      }

      this.store.data.deleteTimetableEntry([id, section.slice(0, 1)])
      this.removeEventById(id, semester)
      this.saveEventsToStore()

      // Open to next available semester, if possible
      if (isEmptyObject(target)) {
        if (this.fallEnabled()) this.handleSemesterChange('fall')
        if (this.winterEnabled()) this.handleSemesterChange('winter')
        if (this.summerEnabled()) this.handleSemesterChange('summer')
      }

      this.hasChanges = true
      this.$gtag.event('delete_course_' + semester + '_' + id, { value: 1 })
    },

    fallUnblock (event) { this._unblockEvent(event, 'fall') },
    winterUnblock (event) { this._unblockEvent(event, 'winter') },
    summerUnblock (event) { this._unblockEvent(event, 'summer') },
    _unblockEvent (event, semester) {
      let deleteFrom
      const { id } = event

      switch (semester) {
        case 'fall': deleteFrom = this.fallCoursesChosen; break
        case 'winter': deleteFrom = this.winterCoursesChosen; break
        case 'summer': deleteFrom = this.summerCoursesChosen; break
      }

      delete deleteFrom[id]

      this.removeEventById(id, semester)

      this.saveEventsToStore()
      this.$gtag.event('timetable_create_unblock_time', { value: 1 })

      this.hasChanges = true
    },

    toggleSemester (semester) {
      // Minimum one required
      if (this.semestersChosen.length === 1 && this.semestersChosen[0] === semester) return

      if (this.semestersChosen.includes(semester)) {
        this.semestersChosen = this.semestersChosen.filter(e => e !== semester)
        return
      }

      this.handleSemesterChange(semester)
    },
    handleSemesterChange (newSem) {
      if (this.semestersChosen.includes(newSem)) return

      if (this.onlyOneSemesterAllowed()) {
        this.semestersChosen = [newSem]
      } else {
        if (this.semestersChosen.length === 2) this.semestersChosen.shift()
        this.semestersChosen.push(newSem)
      }

      this.semestersChosen = dedupe(this.semestersChosen)
    },
    onlyOneSemesterAllowed () {
      return this.$vuetify.breakpoint.width < 1634
    },

    handleEventClick (data) {
      const courseId = data.event.id
      const section = data.event.section

      this.courseSelected = courseId
      this.courseSelectedColor = data.event.color
      this.semesterSelected = section[0] + ' 20' + section.slice(1, 3)

      if (isLectureFallSection(section)) this.$gtag.event('timetable_event_click_fall', { value: 1 })
      if (isLectureWinterSection(section)) this.$gtag.event('timetable_event_click_winter', { value: 1 })
      if (isLectureSummerSection(section)) this.$gtag.event('timetable_event_click_summer', { value: 1 })

      this.timetableModal = true
    },

    handleFallBlockTime (data) { this._createBlockTime(data, 'F99 - Blocked') },
    handleWinterBlockTime (data) { this._createBlockTime(data, 'W99 - Blocked') },
    handleSummerBlockTime (data) { this._createBlockTime(data, 'S99 - Blocked') },
    _createBlockTime (data, section) {
      this.$gtag.event('timetable_create_block_time', { value: 1 })
      const blockedEvent = createBlockedOffEvent(data.day, data.startHour, data.endHour, section)
      this.handleCourseSelectionDone(blockedEvent)

      this.hasChanges = true
    },
    handleLockToggle ({ id, section }) {
      this.$gtag.event('timetable_event_lock_toggle', { value: 1 })
      const uniqueId = getUnaryConstrainKey(id, section)
      if (this.lockedSections[uniqueId]) {
        delete this.lockedSections[uniqueId]
      } else {
        this.lockedSections[uniqueId] = true
      }

      // Force a new create so properties update
      // TODO: Only create events for the section the course belongs to...
      this.resetEventsFor('all')
    },
    async generate (semester) {
      let coursesForSection, generatorOpts

      switch (semester) {
        case 'fall':
          coursesForSection = this.fallCoursesChosen
          generatorOpts = this.fallGeneratorOptions
          break
        case 'winter':
          coursesForSection = this.winterCoursesChosen
          generatorOpts = this.winterGeneratorOptions
          break
        case 'summer':
          coursesForSection = this.summerCoursesChosen
          generatorOpts = this.summerGeneratorOptions
          break
      }

      // Always work on a deep copy
      const courses = deepClone(coursesForSection)

      // Must have at least ONE course chosen!
      if (Object.keys(courses).length === 0) return

      // Only persist locally if not logged in
      if (!this.scheduleMeta.id) this.saveEventsToStore()

      const { error } = await Generate({
        courses,
        generatorOpts,
        target: coursesForSection,
        lockedSections: this.lockedSections
      })

      if (error) {
        switch (error) {
          case 'constraints':
            this.$toast.error(this.snackbars.constraintsError.msg)
            break
          case 'generator_error':
            this.$toast.error(this.snackbars.unknownError.msg)
            break
          default:
            this.$toast.error(this.snackbars.unknownError2.msg)
            throw new Error(error)
        }

        console.error('Failed to generate timetable!', error)
      } else {
        this.resetEventsFor(semester)
      }
    },
    handleCourseSelected (course) {
      this.courseSelected = course.split(':')[0]
      this.timetableModal = true
      this.showCourses = false
    },
    handleBulkCoursesSelected (courseMapping) {
      [
        ...(courseMapping.fall),
        ...(courseMapping.winter),
        ...(courseMapping.summer)
      ].forEach((courseData) => {
        courseData = deepClone(courseData)

        // Format in a way that can be sent to `handleCourseSelectionDone`
        // Only "code" property in "courseResult" seems to be used...could be made simpler...
        const selection = { data: { courseResult: { code: courseData.code }, timetableResult: { timetable: {} } } }

        selection.lecture = courseData.chosen.lecture
        selection.tutorial = courseData.chosen.tutorial
        delete courseData.chosen

        selection.data.timetableResult.timetable = courseData
        selection.data.timetableResult.timetable = courseData

        sanitizeCourseData(selection)
        this.handleCourseSelectionDone(selection)
      })

      // First action that user does might be bulk upload. Make sure
      // the semester is visible for them right away
      if (this.semestersChosen.length === 0) this.openToFirstAvailableSemester()
    },
    handleCourseSelectionDone (selection, opts = {}) {
      let semester, coursesChosenToUpdate
      const { data, lecture, tutorial, isBlockedTime, color } = selection
      // Filter out blocked time for users viewing another users' timetable
      if (this.scheduleMeta.id && !this.canEditTimetable && this.scheduleExists && isBlockedTime) return

      const courseCode = data.courseResult.code
      const isFall = isLectureFallSection(lecture)
      const isWinter = isLectureWinterSection(lecture)
      const isSummer = isLectureSummerSection(lecture)
      const year = getYearFromSection(lecture)

      // So we can display "Fall 2021", "Winter 2022", etc.
      if (!selection.isBlockedTime) {
        if (isFall) this.fallYear = year
        if (isWinter) this.winterYear = year
        if (isSummer) this.summerYear = year
      }

      if (isFall) {
        semester = 'fall'
        coursesChosenToUpdate = this.fallCoursesChosen
      } else if (isWinter) {
        semester = 'winter'
        coursesChosenToUpdate = this.winterCoursesChosen
      } else {
        semester = 'summer'
        coursesChosenToUpdate = this.summerCoursesChosen
      }

      // If course already exists, update it!
      const updated = { data, lecture, tutorial, isBlockedTime }

      let exists = coursesChosenToUpdate[courseCode]

      if (!exists) {
        exists = { }
        updated.color = color || getRandomColour()
        if (isBlockedTime) updated.color = 'grey'
      }

      const newCourseData = { ...exists, ...updated }

      coursesChosenToUpdate[courseCode] = newCourseData
      if (exists) {
        this.removeEventById(courseCode, semester)
      }
      this.addCourseToEvents(newCourseData, newCourseData.data.courseResult.code, semester)

      this.courseSelected = ''

      this.saveEventsToStore()
      this.handleSemesterChange(semester)
      InvalidateCSPCache()

      this.hasChanges = true
    },

    /** When user updated which section is selected in edit panel */
    handleCourseUpdate ({ code, semester, type, section }) {
      let destination, fullSemesterName

      switch (semester) {
        case 'F': destination = this.fallCoursesChosen; fullSemesterName = 'fall'; break
        case 'W': destination = this.winterCoursesChosen; fullSemesterName = 'winter'; break
        case 'S': destination = this.summerCoursesChosen; fullSemesterName = 'summer'; break
      }

      destination[code][type] = section

      // Refresh events for only this course
      this.removeEventById(code, fullSemesterName)
      this.addCourseToEvents(destination[code], code, fullSemesterName)
    },
    async toggleSharedStatus () {
      if (this.loading.scheduleShared) return

      const newVal = !this.scheduleMeta.shared

      try {
        this.loading.scheduleShared = true

        const resp = await fetch('/rest/v1/schedule/setShareStatus/' + this.scheduleMeta.id, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            shared: newVal
          })
        })

        if (resp.ok) this.scheduleMeta.shared = newVal
        else this.$toast.error('Failed to set shared status of timetable.')
      } catch (error) {
        this.scheduleMeta.shared = false
        this.$toast.error('Failed to set shared status of timetable.')
        console.error('Failed to update shared status:', error)

        Bugsnag.notify((error))
      } finally {
        // Prevent spamming checkbox
        setTimeout(() => { this.loading.scheduleShared = false }, 500)
      }

      this.$gtag.event('timetable_toggle_share_status', { value: 1 })
    },
    async saveTimetable (newName = null, forceNew = false) {
      this.loading.saveTimetable = true

      if (!this.store.user.userInfo) {
        if (forceNew || !this.canEditTimetable) {
          this.eventQueue.copyTimetable = true
        } else {
          this.eventQueue.saveTimetable = true
        }
        this.needAuthentication = true
        return
      } else if (!this.store.user.userInfo.verified) {
        this.$toast.warning('Saving & copying timetables requires a verified account!')
        return
      }
      // Bundle courses into single list
      const allCourses = []
      const semesters = [this.fallCoursesChosen, this.winterCoursesChosen, this.summerCoursesChosen]

      semesters.forEach(sem => {
        for (const course in sem) {
          const courseToAdd = deepClone({ ...(sem[course]) })

          courseToAdd.isBlockedTime = !!courseToAdd.isBlockedTime
          delete courseToAdd.data.timetableResult.filters

          // TODO: Make this cleaner
          if (courseToAdd.data.timetableResult.timetable) {
            courseToAdd.data.timetableResult.timetable = {
              _id: courseToAdd.data.timetableResult.timetable._id
            }
          }

          allCourses.push(courseToAdd)
        }
      })

      const commonRequest = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: newName || this.scheduleMeta.name,
          meta: {
            fallGeneratorOptions: this.fallGeneratorOptions,
            winterGeneratorOptions: this.winterGeneratorOptions,
            summerGeneratorOptions: this.summerGeneratorOptions
          },
          courses: allCourses
        })
      }

      // TODO: Validations for number of courses allowed per sem
      if (this.scheduleMeta.id && !forceNew) {
        const resp = await fetch('/rest/v1/schedule/update/' + this.scheduleMeta.id, commonRequest)
        const { msg } = await resp.json()

        if (resp.ok) {
          this.hasChanges = false

          this.$toast.info('Timetable saved!', { timeout: 1500 })
          this.editPanel = false
        } else {
          console.error(resp)
          this.$toast.error(msg || 'Could not save the timetable: ' + resp.statusText)
        }

        this.$gtag.event('timetable_save', { value: 1 })
      } else {
        // Create a new timetable
        const resp = await fetch('/rest/v1/schedule/new', commonRequest)

        if (!resp.ok) {
          const { msg } = await resp.json()
          this.$toast.error(msg || 'Could not create a timetable: ' + resp.statusText)
          return
        }

        const data = await resp.json()

        // Take user to new page
        // This is only done ONCE when creating a NEW schedule
        this.scheduleMeta.id = data.id
        await this.$router.replace({ query: { id: data.id } })
        await this.loadSchedule()

        this.$gtag.event('timetable_create_new', { value: 1 })
      }

      this.scheduleMeta.name = newName || this.scheduleMeta.name

      // TODO Fix hacky logic lol
      setTimeout(() => {
        this.hasChanges = false
      }, 50)

      // Prevent spam-clicking save
      setTimeout(() => { this.loading.saveTimetable = false }, 500)
      await this.getSchedules()
    },
    saveEventsToStore () {
      const allCourses = []
      const semesters = [this.fallCoursesChosen, this.winterCoursesChosen, this.summerCoursesChosen]

      semesters.forEach((semester) => {
        for (const course in semester) { allCourses.push(semester[course]) }
      })
      this.store.data.saveTimetableEntry(allCourses)
    },
    addCourseToEvents (course, courseId, semester, opts = {}) {
      switch (semester) {
        case 'fall': CalendarEventManager.addCourse(course, courseId, this.fallEvents, opts); break
        case 'winter': CalendarEventManager.addCourse(course, courseId, this.winterEvents, opts); break
        case 'summer': CalendarEventManager.addCourse(course, courseId, this.summerEvents, opts); break
      }
    },
    removeEventById (courseId, semester) {
      switch (semester) {
        case 'fall': CalendarEventManager.removeEvent(courseId, this.fallEvents); break
        case 'winter': CalendarEventManager.removeEvent(courseId, this.winterEvents); break
        case 'summer': CalendarEventManager.removeEvent(courseId, this.summerEvents); break
      }
    },
    resetEventsFor (forSection) {
      console.warn('Resetting events for:', forSection)
      switch (forSection) {
        case 'fall':
          this.fallEvents = createCalendarEventsFromSectionData(this.fallCoursesChosen)
          break
        case 'winter':
          this.winterEvents = createCalendarEventsFromSectionData(this.winterCoursesChosen)
          break
        case 'summer':
          this.summerEvents = createCalendarEventsFromSectionData(this.summerCoursesChosen)
          break

        case 'all':
          this.fallEvents = createCalendarEventsFromSectionData(this.fallCoursesChosen)
          this.winterEvents = createCalendarEventsFromSectionData(this.winterCoursesChosen)
          this.summerEvents = createCalendarEventsFromSectionData(this.summerCoursesChosen)
          break
      }
    },
    resetCalendar () {
      this.name = ''
      this.scheduleMeta.name = 'Untitled'
      this.fallEvents = []
      this.winterEvents = []
      this.summerEvents = []

      this.fallCoursesChosen = { }
      this.winterCoursesChosen = { }
      this.summerCoursesChosen = { }

      this.semestersChosen = []
    },
    loadCart () {
      this.store.data.timetableCourses.forEach((course) => {
        this.handleCourseSelectionDone(course)
      })

      this.openToFirstAvailableSemester()
    },
    handleUpdate (newEntry) {
      if (newEntry) {
        this.timetableModal = false
        this.handleCourseSelectionDone(newEntry)
      }

      this.makeSelection = false
    }
  }
}
</script>

<style scoped>
.fullScreen {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 100;

  background: var(--v-background-base);

  margin: 0;

  overflow-y: auto;
}
div.contentCol.fullScreenContentCol {
  max-width: unset;
  width: 80vw;
  padding: 25px 0 0 0;
  height: 100vh;
}

.roundedCorner {
  border-radius: 6px;
}

>>>.v-input__slot {
  margin: 0 !important;
}
>>>.v-badge--icon .v-badge__badge {
  padding: 3px 6px;
}

</style>
